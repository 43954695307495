export function scaleImage(imageFile, maxPixels, quality = 1) {
   return new Promise((resolve, reject) => {
      const img = new Image();
      const reader = new FileReader();

      reader.onload = (e) => {
         img.src = e.target.result;
         img.onload = () => {
            let { width, height } = img;

            // Determine the scale factor based on the maxPixels parameter
            const maxDimension = Math.max(width, height);
            if (maxPixels && maxDimension > maxPixels) {
               const scaleFactor = maxPixels / maxDimension;
               width = width * scaleFactor;
               height = height * scaleFactor;
            }

            // Create a canvas to resize the image
            const canvas = document.createElement("canvas");
            canvas.width = width;
            canvas.height = height;
            const ctx = canvas.getContext("2d");
            ctx.drawImage(img, 0, 0, width, height);

            // Convert the canvas back to a blob
            canvas.toBlob(
               (blob) => {
                  if (blob) {
                     resolve(new File([blob], imageFile.name, { type: imageFile.type }));
                  } else {
                     reject(new Error("Image scaling failed."));
                  }
               },
               imageFile.type,
               quality
            );
         };
      };

      reader.onerror = () => reject(new Error("Failed to read the image file."));
      reader.readAsDataURL(imageFile);
   });
}
