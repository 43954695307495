import React, { useCallback, useEffect, useState } from "react";
import PersonalInformation from "./PersonalInformation";
import { Button, Col, Row, notification } from "antd";
import DeactivateAccountModal from "./DeactivateAccountModal";
import { usePersonalDataStore, useProfileStore } from "../../pages/auth/authStore";
import BasicModal from "../../ui/BasicModal/BasicModal";
import FileUpload from "../../ui/Uploaders/FileUpload";
import { httpService } from "../../base/services/httpService.service";
import { StatusCodesEnum } from "../../utils/enums";
import { ReactComponent as EditIcon } from "../../assets/icons/edit-icon.svg";
import AuthService from "../../base/services/authentication.service";
import { withErrorBoundary } from "../ErrorBoundary/withErrorBoundary";
import { scaleImage } from "../../utils/scaleImage";

import "./index.scss";

const ProfileInformation = () => {
   const user = AuthService.getUser();
   const organizationId = AuthService.getOrganizationId();
   const [isModalOpen, setIsModalOpen] = useState(false);
   const [openPhotoModal, setOpenPhotoModal] = useState(false);
   const { userLetter } = useProfileStore();
   const { formData, getUserAndOrganizationInfo } = useProfileStore();
   const personalData = usePersonalDataStore((state) => state.personalData);
   const setPersonalData = usePersonalDataStore((state) => state.setPersonalData);

   const fetchData = useCallback(async () => {
      await getUserAndOrganizationInfo(organizationId, user.id);
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [organizationId, user.id]);

   const downloadImageAndSetSource = async (imageUrl) => {
      const response = await fetch(imageUrl);
      const blob = await response.blob();

      return new Promise((resolve, reject) => {
         const reader = new FileReader();

         reader.onloadend = async () => {
            const imageFile = new File([blob], "profile-picture.jpg", { type: blob.type });

            try {
               const scaledImage = await scaleImage(imageFile, 150);
               resolve(scaledImage);
            } catch (error) {
               console.error("Error scaling the downloaded image:", error);
               reject(error);
            }
         };

         reader.onerror = reject;
         reader.readAsDataURL(blob);
      });
   };

   const handleUserData = () => {
      fetchData();
      if (openPhotoModal) {
         setOpenPhotoModal(false);
      }
   };

   const getPreviewPhoto = useCallback(() => {
      if (!formData || !formData.userProfilePictureUrl) return;
      let correctedFilePath = formData.userProfilePictureUrl;
      correctedFilePath = correctedFilePath.replace(/\\202/g, "\\x82");
      const payload = [
         {
            fileKey: correctedFilePath
         }
      ];
      httpService
         .post(
            `/FileData/getFileUrlByFileKeyAndEntityId?platformId=006d1c1e-81b6-4d87-b4b0-f1850543f2cc`,
            payload,
            async (response) => {
               const isSuccess = response.statusCode === StatusCodesEnum.OK;
               if (isSuccess && response && response.data[0].fileUrl) {
                  try {
                     const scaledImage = await downloadImageAndSetSource(response.data[0].fileUrl);

                     const scaledReader = new FileReader();
                     scaledReader.onloadend = () => {
                        const updatedUser = {
                           ...personalData,
                           profilePictureUrl: scaledReader.result
                        };
                        setPersonalData(updatedUser);
                     };
                     scaledReader.readAsDataURL(scaledImage);
                  } catch (error) {
                     console.error("Failed to load and scale profile picture:", error);
                  }
               } else {
                  notification.warning({
                     type: "warning",
                     message:
                        response.data.message || "Something went wrong! Please try again later."
                  });
               }
            }
         )
         .catch((error) => {
            notification.error({
               message: error.response?.data?.message || "Unable to display a preview of the image"
            });
         });
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [formData]);

   useEffect(() => {
      fetchData();
   }, [fetchData]);

   useEffect(() => {
      getPreviewPhoto();
   }, [getPreviewPhoto]);

   const handleAccountModal = () => {
      setIsModalOpen((prev) => !prev);
   };

   return (
      <div className="profileInformation accountSettings">
         <div className="userLogo">
            <div
               className="user-profile-logo user-profile-position"
               onClick={() => setOpenPhotoModal(true)}
            >
               {personalData.profilePictureUrl ? (
                  <>
                     <img
                        width={120}
                        height={120}
                        src={personalData.profilePictureUrl}
                        alt=""
                        style={{
                           borderRadius: "100px",
                           border: "4px solid white"
                        }}
                        onClick={() => setOpenPhotoModal(true)}
                     />
                  </>
               ) : (
                  <>
                     <span>{userLetter}</span>
                  </>
               )}
               <span className="user-profile-logo--icon">
                  <EditIcon />
               </span>
            </div>
         </div>
         <div className="profileContent">
            <Row gutter={16} align="middle">
               <Col xs={24} sm={12} lg={12} xl={12}>
                  <p className="title">Profile information</p>
               </Col>
               <Col xs={24} sm={12} lg={12} xl={12} justify="flex-end">
                  <Button type="default" className="defaultButton" onClick={handleAccountModal}>
                     Delete or deactivate account
                  </Button>
               </Col>
            </Row>
            <PersonalInformation handleUserData={handleUserData} />
         </div>
         {isModalOpen && (
            <DeactivateAccountModal
               isModalOpen={isModalOpen}
               handleAccountModal={handleAccountModal}
            ></DeactivateAccountModal>
         )}
         {openPhotoModal && (
            <BasicModal
               isModalOpen={openPhotoModal}
               handleCancel={() => setOpenPhotoModal(false)}
               footerButtonCustomStyle
            >
               <FileUpload
                  handleFileUrl={handleUserData}
                  handleCancel={() => setOpenPhotoModal(false)}
               ></FileUpload>
            </BasicModal>
         )}
      </div>
   );
};

export default withErrorBoundary(ProfileInformation);
