export const InputType = Object.freeze({
   SECTION: 0,
   SELECT_ONE: 32,
   SELECT_MULTIPLE: 33,
   TEXT: 34,
   NUMBER: 35,
   DECIMAL: 36,
   DATE: 37,
   TIME: 38,
   NOTE: 40,
   PHONE_NUMBER: 100,
   DATE_TIME: 104,
   GPS: 105,
   PHOTOGRAPH: 106,
   FILE: 110,
   ACKNOWLEDGE: 112,
   CALCULATE_QUESTION: 113,
   RANGE_QUESTION: 275,
   RATING: 276,
   MATRIX: 277,
   RANKING: 278,
   HIDDEN: 279
});

export const FetchStatusEnum = Object.freeze({
   Idle: "idle",
   Loading: "loading",
   Success: "success",
   Error: "error"
});

export const EntityTypeEnum = Object.freeze({
   Organization: 1,
   Workspace: 2,
   Project: 3,
   Survey: 4,
   // Section: 5,
   // Question: 6,
   Member: 7,
   // Submission: 8,
   // Answer: 9,
   Beneficiary: 10,
   User: 11,
   OrganizationMember: 12,
   Target: 13,
   ImportedBeneficiaryTable: 14,
   Task: 15,
   MessagingTask: 16,
   ProjectMember: 17
});

export const UserRolesEnum = Object.freeze({
   Organization: "d75d01d0-af84-40d8-b37f-ebbbbccf86ed",
   SuperAdmin: "fab4fac1-c546-41de-aebc-a14da6895711",
   JuniorProjectManager: "19485e69-ace9-4c15-54ad-08dbb2ddb282",
   AdminOfOrganization: "a23ccd39-5fe4-44a4-f5f0-08dbb041020b",
   SeniorProjectManager: "6f5b8456-7510-471a-f5ef-08dbb041020b",
   ProjectManager: "afb7b5ef-79f9-41ad-9ba3-f24d5df65391",
   DataCollector: "6e75c185-c1ce-4a84-1426-08dbafa31c5e",
   Guest: "c7d36b3f-6609-4a67-a5c3-926da0a791ca"
});

export const ExcelUserRolesEnum = Object.freeze({
   Organization: "d75d01d0-af84-40d8-b37f-ebbbbccf86ed",
   SuperAdmin: "fab4fac1-c546-41de-aebc-a14da6895711",
   JuniorManager: "19485e69-ace9-4c15-54ad-08dbb2ddb282",
   AdminOfOrganization: "a23ccd39-5fe4-44a4-f5f0-08dbb041020b",
   SeniorManager: "6f5b8456-7510-471a-f5ef-08dbb041020b",
   ProjectManager: "afb7b5ef-79f9-41ad-9ba3-f24d5df65391",
   DataCollector: "6e75c185-c1ce-4a84-1426-08dbafa31c5e",
   Guest: "c7d36b3f-6609-4a67-a5c3-926da0a791ca"
});

export const SurveyStatusEnum = Object.freeze({
   Editing: 0,
   Deployed: 1,
   Archived: 2
});

export const TaskStatusEnum = Object.freeze({
   Assigned: 0,
   Rejected: 1,
   // InProgress: 2,
   Completed: 3
});

export const StatusCodesEnum = Object.freeze({
   OK: 200,
   NotFound: 404,
   BadRequest: 400,
   NoContent: 204,
   Unauthorized: 401
});

export const TranslateStatusEnum = Object.freeze({
   Translated: 0,
   InProgress: 1,
   Archived: 2
});

export const GenderEnum = Object.freeze({
   Male: 0,
   Female: 1,
   Other: 2
});

export const CollectionTypeEnumeratorsEnum = Object.freeze({
   SurveyLink: 0,
   InPerson: 1
});

export const CollectionTypeMessagesEnum = Object.freeze({
   Whatsapp: 0,
   Email: 1
});

export const RecurrenceOptionsEnum = Object.freeze({
   Daily: 1,
   Weekly: 2,
   Biweekly: 3,
   Monthly: 4,
   Yearly: 5,
   EveryXDays: 6,
   NoRecurrence: 7
});

export const WeekDaysEnum = Object.freeze({
   Monday: 1,
   Tuesday: 2,
   Wednesday: 3,
   Thursday: 4,
   Friday: 5,
   Saturday: 6,
   Sunday: 0
});

export const ExportStatusEnum = Object.freeze({
   Succeeded: "Succeeded",
   Failed: "Failed",
   Processing: "Processing",
   Scheduled: "Scheduled"
   // _: "Job state is unknown"
});

export const MainDashboardTabsEnum = Object.freeze({
   SurveyDashboard: "1",
   SurveyResults: "2",
   SurveyDimensions: "3"
});

export const SurveyResultsTabsEnum = Object.freeze({
   GraphsReport: "1",
   TableView: "2"
});

export const LanguagesEnum = Object.freeze({
   ABKHAZIAN: "7B62C645-73BA-4C08-BA89-A9639CF0F7E1",
   AFAR: "B4B15BD5-6F19-41D2-89EC-63FB263BD225",
   AFRIKAANS: "3D9E531F-46A7-4951-B83A-06DDABB65F19",
   AKAN: "41DE51E6-07CD-4B2D-9DAA-6E312AF7A9D9",
   ALBANIAN: "8327C31B-9FE8-4F15-B120-912B04D52801",
   ALEMANIC: "24D39BAD-0C28-4FD8-A2B4-6D140B810ABE",
   AMHARIC: "3D85F3C1-505F-4D8A-97F9-B70D60700593",
   ANGAL: "9B227EB1-0C81-40C1-9DCB-D2A35FDA6A63",
   ANGLO_SAXON_OLD_ENGLISH: "F413244E-CF72-4E7C-A6B3-393198FA0EB6",
   ARABIC: "B63818CB-DA8F-44D6-A578-C9B83E39F5EB",
   ARAGONESE: "1C309AEE-646A-44E3-A4E4-626F93DF8F2D",
   ARAMAIC: "8BA2D2A0-1382-4974-B953-DC0874084545",
   ARMENIAN: "02F83526-9FB9-4937-B2A8-46358A20F0EF",
   AROMANIAN: "164B68B0-331A-4FB9-B3B1-81DE6BFCD545",
   ARPITAN_FRANCO_PROVENCAL: "509A332C-457B-4054-BF07-A5261CB33335",
   ASSAMESE: "9328DD0B-0477-4B54-8A77-7721A52CD7C0",
   ASTURIAN: "A5980D92-4F29-4D22-8DBD-A193D5D4C83F",
   AVAR: "69A5701A-B006-44E2-BE22-2BEF00C9FB7F",
   AWADHI: "C223FD91-2010-4B7E-B53D-4A490E953F66",
   AYMARA: "0FF11F45-6BFF-4C4B-87B2-5B609E17A73F",
   AZERBAIJANI: "49280036-9D2F-41DD-B097-0AD3C4314809",
   BAMBARA: "35135309-BBF5-4E10-893E-9F7EDEC64E37",
   BANYUMASAN: "DA8A3A95-AED7-460B-BE42-C64FFE9D7270",
   BASHKIR: "B5B9311D-5AC3-40B1-819F-9ACE36625512",
   BASQUE: "1FB418E2-A61B-4822-A90B-96FB28BEAFEA",
   BAVARIAN: "96BB7B9F-AA88-4C89-8875-411BC0A29A9F",
   BELARUSIAN: "1C3DDB7D-D532-43D4-8CA1-25647A84FB32",
   BELARUSIAN_TARASKIEVICA: "C84FEE57-8AB5-4CB6-869D-1EE1F4C09B41",
   BENGALI: "68726572-4788-4565-B42F-6AF2A06EF645",
   BIHARI: "E426DC3C-34CE-4A56-8C3B-E27BA1CBB975",
   BIKOL: "083BDE6F-4128-4161-BA93-32C967B18D9D",
   BISHNUPRIYA_MANIPURI: "9AFB0EE5-044E-478C-A31E-ECFD44F311E3",
   BISLAMA: "B21ED82A-8EB8-42AD-B7BA-44B9A93439CB",
   BORO: "5F4040B0-1E4A-43ED-9964-95A6C29D530C",
   BOSNIAN: "63D74F34-4148-4B57-9D60-3C284DC7C678",
   BRETON: "11D94FCA-1AE3-42E4-A1B8-A8BC0F75484A",
   BUGINESE: "FDB35611-3B0B-44AD-A605-25EB3574B9C3",
   BULGARIAN: "5BD5F636-E040-424D-82A9-71132600FE46",
   BURIAT_RUSSIA: "DAADE1B7-FC7D-4506-857D-CB70E074300A",
   BURMESE: "47C82C77-2844-4A66-96EF-E1D805B68018",
   CAMBODIAN: "97177F73-DAC6-45C9-935D-98B6F99274C0",
   CANTONESE: "F66E6A9F-BB63-4DBF-817A-224C956A27AF",
   CATALAN: "B1BEA7A6-CE07-4068-AE9A-FFF80FAC7F33",
   CEBUANO: "ADCD3293-9A77-413A-846C-1A4BA7B57D64",
   CHAMORRO: "1F51D329-D58F-4CFC-A77E-E0A1342FF738",
   CHECHEN: "AB472B79-A0C9-439B-B5C7-2607D40A9F45",
   CHEROKEE: "A61ACB57-ED86-439A-98AC-972C7AE2BBA4",
   CHEYENNE: "B679A8E8-F2A6-422A-AC7B-539ED8E76B49",
   CHICHEWA: "322F23B3-5AAA-42E2-8B05-B7360E27CD76",
   CHINESE: "B058DE34-EADD-4B8D-B5F6-878B4709A37B",
   CHOCTAW: "21269F68-61DD-4BC9-B87D-8762941F4957",
   CHUVASH: "D8818FCE-8A38-4AC9-A58E-2A46B33C16E7",
   CLASSICAL_CHINESE: "C239C00C-8FB8-407F-9487-7377AD001DCF",
   CORNISH: "5A299D1E-46A9-4918-8A78-1DFB63FF4359",
   CORSICAN: "E84068F0-3CF8-41C2-A404-7CB5F20E38D3",
   CREE: "D187A469-3FAA-4F4C-B2A5-FCB203C61DC9",
   CREEK_MUSKOGEE: "2EF380C7-F3F9-4B1D-B00A-0EC1587070B3",
   CROATIAN: "40351741-1A06-4993-B31F-7C26EC5860A9",
   CZECH: "1DBF7FDA-F04D-48F4-95B0-A53E2075A70F",
   DANISH: "4564DD41-3AF9-41A5-9F8F-80AB59DCD90A",
   DIMLI: "7EAE0EFB-E637-497C-A1E1-904DDBE664C3",
   DIVEHI: "23C8F38B-0DC5-45B8-A567-063CAA5DD410",
   DUTCH: "E600AA9B-CBB8-4147-9A70-12DB3E45D2FE",
   DUTCH_LOW_SAXON: "D3758252-96FA-44FF-BA80-68E6544CF60A",
   DZONGKHA: "8FED6412-B028-413F-AE8B-5EAC0B784DED",
   ENGLISH: "ECFB845A-86F0-476C-A4DF-F101D35A96F9",
   ESPERANTO: "B4DE4DF1-C01F-4D14-AE08-66AEE3EEBD57",
   ESTONIAN: "FD566D2E-EC42-4A8C-AE9E-034CD1C7B654",
   EWE: "9A140312-5F2D-4FEF-A3C7-F5320C262AFA",
   EXTREMADURAN: "D254A0F7-C281-422F-91A7-F1E9B67E4CED",
   FAROESE: "68C66661-46EB-4777-A17D-34180587C6A9",
   FIJIAN: "047A3654-E267-460D-A7EF-92B55BD75C95",
   FINNISH: "E2A81702-7ECB-4F43-8CE7-F5003F479F9A",
   FRENCH: "9631BCD0-CB65-49B3-AF53-3868C5763A60",
   FRIULIAN: "F56ACC39-2A0B-4172-AAF9-C2A1EF324A3E",
   GALICIAN: "F59C094D-5EF3-4BD0-AF85-D9AF40A328D4",
   GAN_CHINESE: "3887E676-881E-4470-A085-7E128784C86E",
   GANDA: "DB4F2DF7-FEB6-457A-9B65-83316D8B28B0",
   GARHWALI: "9EBD8841-73BA-4E5E-801B-EE3A312982B0",
   GEORGIAN: "9A23C804-7B97-456B-9598-3C9B1AA7DA78",
   GERMAN: "6A2B458F-38A2-4AA7-A58D-BFFA5302E2A6",
   GILBERTESE: "77755651-E627-479D-9B8B-5763E8D5771C",
   GOTHIC: "D1DB1B60-73B9-4195-BDC0-EB8ECAFFDE70",
   GREEK: "1F0BEAE6-4DB8-4DB4-BB1B-C82E8AC74B82",
   GREENLANDIC: "3B13A24D-21CE-4B19-B2C1-12D6DA513DD5",
   GUARANI: "3A911B31-F29E-436C-9E7D-4A5F93191661",
   GUJARATI: "60C7EB7D-9F53-4BCD-93AE-BEA668560FCA",
   HAITIAN: "9B2B30A5-34C7-49FD-9A78-37D91599125C",
   HAKKA_CHINESE: "973F8AD2-AA1F-4CF6-BF7D-44CD0345BE9C",
   HAUSA: "9029DBA2-3592-4B34-B5B5-5CA9BAF1C93B",
   HAWAIIAN: "8A675C48-A624-42D8-8709-B3B96B3F2033",
   HEBREW: "55A97712-F6F7-42F5-8D78-40AABA8BD9BC",
   HERERO: "BD9CD7CE-7B47-488E-AABF-F5095E014B80",
   HINDI: "250DBA0B-1030-4F6A-9550-9F736429F9B9",
   HIRI_MOTU: "B82217EE-0C6B-4DCE-8399-45EAE9DD3778",
   HUNGARIAN: "E0C1559E-4229-402A-AF8F-7217DFAE9AEE",
   ICELANDIC: "CC056001-A0A6-4FBB-9973-8D6FF05E0969",
   IDO: "B8AAD025-A238-473E-88AF-AAE957EDB6F7",
   IGBO: "680414C4-9E9C-444B-82C9-8C711023E26D",
   ILOKANO: "E37F0F8E-A8C2-423E-9FFA-50B397650A5A",
   INDONESIAN: "FBC81131-65DC-4C39-9F98-5B62FD7B1B50",
   INGUSH: "8B852A40-EBF5-4044-A616-9E84225760EF",
   INTERLINGUA: "D7913AF7-CA1E-43BE-99DB-C7A7372F196F",
   INTERLINGUE: "1523BF3F-78BB-4DE2-A7F8-231C7F1802F2",
   INUKTITUT: "0831B446-C040-48D8-876B-E727BD79E435",
   INUPIAK: "18267E55-A84C-44E9-8F3C-9FC4C87C0AFD",
   IRISH: "C3A6CFF4-4310-4773-B6A4-B7D040D5538A",
   ITALIAN: "CC8585D4-DAE4-4BF8-BEC5-FF7C29ABAFC9",
   JAPANESE: "8E210041-EA52-4C7B-AD23-F119D9D196A1",
   KALMYK: "64A69B9F-9CBD-4362-8F2A-1C2F04463932",
   KANNADA: "1B71385F-2CDC-4680-9DDF-03ECADE1380E",
   KANURI: "1EC83EB6-7096-4811-96FF-B52F6074FC80",
   KAPAMPANGAN: "BD1DC6FF-29A5-4C91-9FC4-3988E8595050",
   KASHMIRI: "455A4128-5133-4C66-9622-7A0BC3701BC0",
   KASHUBIAN: "0F6E93E6-9E86-4DED-9F32-21DBAE68373A",
   KAZAKH: "7E08E51F-211E-46C4-B479-041BAE1AC9F9",
   KHOWAR: "7ABA6CEA-E37F-4D4A-A30D-C0A41B57F0BE",
   KIKUYU: "8556F874-6724-4EF0-80C7-6B2FE8CE32F5",
   KIRGHIZ: "CC468705-5340-4332-9D54-ED147FE9635F",
   KIRUNDI: "7FEAFA4C-3EAF-4515-803E-F361AD9CAF3D",
   KLINGON: "875D352B-813F-4900-B383-9ACAFE959EAA",
   KOMI: "C52DB3E8-ACDF-414F-8A35-E1BFCE8951ED",
   KONGO: "B1163297-34AF-4F8D-B1FC-CE49F96A1257",
   KOREAN: "25AF2DEB-D973-41FF-BDCF-78818DEA7D91",
   KUANYAMA: "7FEAFA4C-3EAF-4515-803E-F361AD9CAF3D",
   KURDISH_KURMANJI: "DA988A36-52D8-48E4-A459-010CEF7AE928",
   KURDISH_SORANI: "D29AD44F-78C7-403C-AD8A-0406CA58CED3",
   LADINO_JUDEO_SPANISH: "21D01C57-8674-4B55-8CD5-F8561FB13E0B",
   LANGO: "70E90732-6ECB-4D70-8C6B-93F89C08DE07",
   LAOTIAN: "F063181E-2BBA-4644-9849-FCF294AA5ECE",
   LATIN: "419B3551-30FB-49A5-B777-FE0BC1C25C70",
   LATVIAN: "45B87A5A-F119-4825-8875-EF15738EF91A",
   LAZ: "A757E6BD-B4C0-44CF-B630-75E83C22DC32",
   LIGURIAN: "0896A99C-F9E4-4239-8E49-044FBFFD63AE",
   LIMBURGIAN: "DC05C507-95C9-4578-8E00-0277F75AEDEB",
   LINGALA: "5E5166DA-5D7D-4E7E-839F-73EF8889B004",
   LITHUANIAN: "F0EA9C61-CFA3-4BE3-B1C9-0B2E17AD2BB2",
   LOJBAN: "A0AE390B-DE0B-4A27-8986-841484E5BDEC",
   LOMBARD: "C72F113B-5C91-467E-9F81-9C13157144B9",
   LOW_GERMAN_LOW_SAXON: "40F4FBA4-E536-4566-98FD-F53F7C6A922F",
   LOWER_SORBIAN: "F5DD650A-EB31-4839-9A9F-F251B07366DB",
   LUXEMBOURGISH: "26D94660-1C85-441A-88C3-1DEE1D0E3E0B",
   MACEDONIAN: "1080C874-C29D-4ACC-A44C-9C04C31F8A93",
   MALAGASY: "73226510-C20D-4ECF-AD01-97E4B9348097",
   MALAY: "6107A0FF-D2C3-42E5-B49B-85199B8334E9",
   MALAYALAM: "E114306C-4858-4E55-AE47-4E98C0831ACB",
   MALTESE: "A8393EAF-2105-4206-8F93-54B7389853CD",
   MANDARIN: "29A85B1A-D01B-4309-A697-1292DE65D97A",
   MANX: "A49625FE-A406-4F7B-896F-E1620C34FDE1",
   MAORI: "8EC5E570-EC5E-4206-8B05-783857B154A1",
   MARA: "C9E144FB-6ABA-450A-B5D2-9BB704DE6959",
   MARATHI: "38FE8CCF-C1DF-43D6-A3A8-FE5DC94A0242",
   MARSHALLESE: "751C1D97-CC4F-414E-8614-B5052F9B5DF7",
   MEGRELIAN: "5AECF79F-2E4C-4D92-9F9E-C292ECEE2022",
   MIN_DONG_CHINESE: "106BC97C-96E1-47E7-8BC3-5A6B5D844037",
   MINANGKABAU: "BA451F54-970C-43F0-97E6-EA65A64C1420",
   MINNAN: "52132932-026A-4D9D-8548-6D558BEA3E0B",
   MIRANDESE: "C8DC08CA-A3BE-405A-840E-F5F80EBF4DDF",
   MOLDOVAN: "4D1728C8-9526-45F3-9233-E563AD6DC3CB",
   MONGOLIAN: "7DA1BB5C-0DCA-424C-99AC-9F9682E40581",
   NAHUATL: "73CE8018-8C08-4A9B-9D73-E904AB23DAD4",
   NAURUAN: "F6BC8EF9-8D52-4786-B621-C60D16B233E4",
   NAVAJO: "5B372168-2E06-4DF4-B2D1-A48E8A1A4FE7",
   NDONGA: "2FF58BE2-CC27-46F9-BD5A-39789B1C0007",
   NEAPOLITAN: "5289D032-5AAD-47AE-A463-AB78868BB6CE",
   NEPALI: "EA5DB974-6A6C-42A8-A60A-74D135F3F754",
   NEWAR: "572385C4-CDAE-47B9-AB60-6F2A462AAEC5",
   NORFOLK: "D309518D-02CB-4CC9-821D-84AB9BD3CB5D",
   NORMAN: "EC3C383B-0D55-41A7-B9CA-9616D18E3FD9",
   NORTH_NDEBELE: "0EA45054-B71B-4704-BEF7-A4FB421ABA06",
   NORTHERN_SAMI: "946DAA52-5324-4962-A709-0D7A06F06CCD",
   NORTHERN_SOTHO: "F1E1426A-A2B6-4E0E-9078-C18D15550DA2",
   NORWEGIAN: "88EC0A17-44FD-48C5-B549-1B2F0A6681AE",
   NORWEGIAN_NYNORSK: "756546CF-0E9B-40DC-9E7B-2A4ABD5649AC",
   OCCITAN: "FA9E1AD5-6D34-4C61-880E-9E0824AE0CA4",
   OJIBWA: "ECFDF618-2400-423C-BBCE-D473887871D9",
   OLD_CHURCH_SLAVONIC_OLD_BULGARIAN: "1827E5E7-F4AE-4BDB-83F3-1CF5EAED3238",
   ORIYA: "8D441BBB-A58A-4033-8705-CECA9A697FB9",
   OROMO: "88C09ADD-D8FE-4799-81D3-77C844A5E914",
   OSSETIAN_OSSETIC: "70B683C9-31C6-4284-A4D7-FA0B329FBE54",
   PALI: "F4651CD3-6A3C-4E50-8F0F-4BB637639AB3",
   PANGASINAN: "F84A4460-E369-4013-BD51-A6B4331F0368",
   PANJABI_PUNJABI: "083B3ACD-7492-49C2-B6BD-362C447898CF",
   PAPIAMENTU: "5C261EDD-08E9-4B9D-A457-29B8A258DBF7",
   PASHTO: "B8CC5F4A-F491-46EF-A539-51017DF05127",
   PENNSYLVANIA_GERMAN: "AD5E7C9D-43D7-4561-A32B-D46C4EE15DFC",
   PERSIAN: "E9DF9D87-A193-49C1-A476-249270054D9C",
   PEUL: "EEDFAB1E-1952-4167-A739-84752E69910E",
   PIEDMONTESE: "FC1E2688-3F27-4F8C-AC79-CC884BE65BCE",
   POLISH: "97B5D35F-15C2-4428-8882-4A0C10839437",
   PORTUGUESE: "39CB57D6-1118-42A9-AD20-D892D668A38D",
   QUECHUA: "24966B56-4D24-42BA-B7D5-FFBF3FAD43D6",
   RAETO_ROMANCE: "531D5FCB-D730-4A39-ABD5-014F3C976896",
   RIPUARIAN: "ABBF00BD-594D-4246-963B-33BB8FD7C496",
   ROMANI: "0BAF61EC-6B89-4B19-9C9E-1B70AC50169D",
   ROMANIAN: "CFD689C6-C7AE-4C73-9A67-239E17F3113C",
   RUSSIAN: "83251EE4-2A5A-4F38-A24D-82861D151737",
   RWANDI: "8AB4B632-8E27-43AD-B53F-91DCD19344AC",
   SAMOAN: "6E37252D-CBE1-4F34-B473-1E1FC573A959",
   SAMOGITIAN: "363C17EB-0761-400F-8668-2BE2D4090C68",
   SANGO: "D62FC51E-A4DB-4917-99AF-A2E2EBF6C5E2",
   SANSKRIT: "3698B8C8-7FE4-4DD2-94EC-5295C7498A93",
   SARDINIAN: "CD9B04BF-7D20-44B1-AB1C-BF15591394FF",
   SCOTS: "133DB9C0-7BCE-42BD-B8A3-C4EF3810EC89",
   SCOTTISH_GAELIC: "D69C217D-25D1-4A61-9F59-A6A082B0E088",
   SERBIAN: "CB5807DB-513E-445D-B68E-0E710CC373F8",
   SERBO_CROATIAN: "34C48832-58C8-43D2-B4D1-FE3702D70315",
   SHONA: "EEA660AA-4511-4A56-B1E9-32729D65813B",
   SICHUAN_YI: "F8DC4127-1C51-43BD-AA62-A20D1DFD6E5E",
   SICILIAN: "87A13033-A826-4DB7-BEDF-069DCC31B01E",
   SIMPLE_ENGLISH: "4C08E936-8C77-4578-B654-1BC1467E2B3C",
   SINDHI: "F6BACBAB-1B89-4235-930B-DEF4EAC0B2FB",
   SINHALESE: "B6B60015-4E3A-4B70-B7C9-CF41C51CBEAB",
   SLOVAK: "D97140F4-3386-401E-8470-4FD7D983D5BC",
   SLOVENIAN: "78030991-C57C-472A-83F0-F2A27F987599",
   SOMALIA: "1856DEBF-6997-4101-828C-183A54A1A573",
   SOUTH_NDEBELE: "8FBF9C1A-FC3F-4ABA-BEEA-B13F693CB5C5",
   SOUTHERN_SOTHO: "A94362F6-98EE-48E0-8137-16CBFB016672",
   SPANISH: "B60C8BAD-0C06-4F3D-80C6-98698CC2E49E",
   SUNDANESE: "44A16618-1EA9-49C3-B37B-B0CE0DAC6EAA",
   SWAHILI: "9B2B3E07-00A1-4D0A-BCBA-8E0BB0A6CF60",
   SWATI: "CCC0E73B-FFA4-4381-9067-57B61BD36488",
   SWEDISH: "BB506E0B-65F8-4137-B2EF-C359018B07DE",
   TAGALOG: "73092838-1CCE-4379-BA27-0DE263579218",
   TAHITIAN: "51D8DA53-75A6-40F0-A734-EF73FC46B9D0",
   TAJIK: "2BF36F3A-B380-454E-B525-8B0E15852361",
   TAMIL: "7C30FBCA-EC0F-4EBA-BC89-E342390EC903",
   TATAR: "FCF0AAF9-2225-4BE5-97C5-2E4D6FC314F1",
   TELUGU: "6DB18956-4C38-48A3-891A-013B8CD685BE",
   TETUM: "4F917F26-1855-43DE-9FF2-7B8DDD1559FF",
   THAI: "D2882B11-80C2-4331-95B2-B553591C665E",
   TIBETAN: "B83543F3-F3D9-4077-A143-423E5800A120",
   TIGRINYA: "DB5E4379-164C-47E3-927A-EFDCE6F8647C",
   TOK_PISIN: "D2DEF950-1EBF-4E9C-990C-7B9274BF6DBA",
   TONGA: "1F666191-29A0-4DFE-B908-C2DDEDAE9A4C",
   TSONGA: "19A4CADC-A1EA-4B5E-9590-D0A97C0AC3E2",
   TSWANA: "AD921E07-C554-4564-874C-0286590F6325",
   TUMBUKA: "F79F58F1-6AD5-45C0-AF2B-22C9D20C638A",
   TURKISH: "1D35A722-0F8D-4F92-967B-94951B6386F8",
   TURKMEN: "971F8A30-17EB-4CA9-A4C0-72C23BA78D3A",
   TWI: "289C0DD0-1B1F-4ACC-9F24-53A7DA1CC74A",
   UDMURT: "E54B15A4-6058-4455-AFB0-FBEB9337191C",
   UKRAINIAN: "227B35CB-82D7-4691-9643-93CB66093D96",
   URDU: "6C4DD55C-6F62-4EAB-9ECC-CF788CC34010",
   UYGHUR: "4910339F-5399-42D6-884C-979EB097EE67",
   UZBEK: "C3632F5D-72E2-4C5B-80ED-E44F8CAC6420",
   UZBEKI_AFGHANISTAN: "2D5C5108-17D0-424A-847F-98C10D1DF577",
   VENDA: "B1E1C194-A81A-479E-8804-01BC7F0996C6",
   VENETIAN: "FE6FB276-6FE3-48B6-B7C7-2DD32CDA9612",
   VIETNAMESE: "93A596C1-2A0E-4344-9268-364BDE4B6D4D",
   VOLAPUK: "CC51A53A-2B7B-4697-AB5E-96DBAD5C94CE",
   VORO: "89EAD2E0-E64F-4C0A-945D-16A38C6C57E5",
   WALLOON: "58A3BD20-8A18-49A1-B9B5-C6A11176D6EF",
   WARAY_SAMAR_LEYTE_VISAYAN: "779505F3-30B1-4D38-BFD7-578F944B4D77",
   WELSH: "788E7F10-FE91-4E04-8E4D-C81BB09BD7E9",
   WEST_FLEMISH: "E4CD946F-F3AF-40EF-AE1C-83EAF28DAA77",
   WEST_FRISIAN: "4D7FBA63-67F4-4AAD-BE7B-109ECF527296",
   WOLOF: "3803A953-84B0-42AC-BF28-183C69E8E8B1",
   XHOSA: "48B2DE5B-0B58-410D-B91E-22B9ED1D9698",
   YIDDISH: "87C0EB9F-381B-4AB9-868A-E12311D7FA3C",
   YORUBA: "54AC4E3C-D68E-49A4-A5C4-19A30E7958DE",
   ZHUANG: "E6E5C437-C079-43EC-8D63-8676F4676D65",
   ZULU: "2DA12F09-36FC-4B19-A066-C791F607DBF1"
});

export const CountriesEnum = Object.freeze({
   AFGHANISTAN: "F5138053-D0DA-43FC-855F-E4F4D0913CA5",
   ALBANIA: "598BF2F4-40E4-4485-8A6D-2FA544E51033",
   ALGERIA: "79A48B4E-48C2-47B1-8B19-F83189E4C47C",
   ANDORRA: "72D47293-F451-4652-BF9F-C8017D44A487",
   ANGOLA: "8FD4EB59-98DC-4674-8CB9-2AB1ACE81312",
   ANTIGUA_AND_BARBUDA: "7200E811-5E41-4084-B3AC-319F711AD4A2",
   ARGENTINA: "C7665DB3-F75D-474D-A8D1-15140F59B3FC",
   ARMENIA: "1BF8DB3B-3D6D-44AB-ADA3-48073CBF1A59",
   AUSTRALIA: "3CB96406-7022-4C4C-9D45-0981AECF34FE",
   AUSTRIA: "761F9F2D-EA2B-4F43-BA89-29675B0B53C9",
   AZERBAIJAN: "A61338FE-9633-4451-836E-6814821EDDA5",
   BAHAMAS: "2C92D62D-7031-422A-8CF0-A95B5924EB5A",
   BAHRAIN: "42B74656-3666-43C6-804B-7CFD29511063",
   BANGLADESH: "968A72F2-B5E8-444D-BEE3-B6B52A8D920A",
   BARBADOS: "522468AA-1150-4A3D-A2CE-225F60CA36FC",
   BELARUS: "55ABAF50-6E2F-45E0-8742-97F64B38A25C",
   BELGIUM: "95F70715-3628-495C-B20A-5B1A14B003DC",
   BELIZE: "E98A3CFC-9279-4424-851B-EAB81165FB8C",
   BENIN: "64672A4E-519E-4AAD-8A83-E14CFDBB4F95",
   BHUTAN: "6072A2A6-7D8F-4EE3-BF38-3D5419EDAAAC",
   BOLIVIA: "8A7989F5-E3AD-4340-82CD-575A689F4755",
   BOSNIA_AND_HERZEGOVINA: "6C0347E1-74F9-4DD3-9886-7D17521B1221",
   BOTSWANA: "AA8D4D9D-1F5C-4C9B-B67F-D585BFEB5EAB",
   BRAZIL: "9780C67E-4028-4E0D-8562-0ECE60711F7E",
   BRUNEI_DARUSSALAM: "412C0616-689E-4557-A73C-2AA1602767E1",
   BULGARIA: "6653F58D-C7FB-4F80-93B6-636AA24C3B30",
   BURKINA_FASO: "81C41DDF-BBAE-4C27-9770-CAA40599DEC4",
   BURUNDI: "C28876BA-7C89-4010-BD0E-71603097D438",
   CABO_VERDE: "69B5CCB1-3589-4989-BF10-74F1354CF0EB",
   CAMBODIA: "4CB44A3C-BEC3-4B82-9669-DA7E56626C9F",
   CAMEROON: "56C928E8-39AA-4E0B-B6B2-8D7265DAE278",
   CANADA: "49338E57-588F-4F57-B324-505DB1FC8744",
   CENTRAL_AFRICAN_REPUBLIC: "E612E862-F29F-49D7-8189-EF17FBBC159A",
   CHAD: "D8B57250-5CD6-4EC8-A0A3-2B9768F0D392",
   CHILE: "496BD9C1-54F0-4EE8-8D4A-0ED8C0503205",
   CHINA: "F466DF69-25F4-42E1-9162-4E07D12A8848",
   COLOMBIA: "8F635D0B-2DD5-4201-A25A-B0681B81A190",
   COMOROS: "39A3AAA4-7395-4211-BFA9-7727574DB1CA",
   CONGO_DEMOCRATIC_REPUBLIC: "A2821405-33B4-43DC-B418-49E2102035D7",
   CONGO: "57B206F9-4AA5-44F8-A1F8-7266A9B6A3C7",
   COSTA_RICA: "7D884A7E-EF6C-4550-B7EF-F6C7FFED4965",
   COTE_D_IVOIRE: "8BDD1B3D-4F15-4C9D-A16B-F698F094743F",
   CROATIA: "A0436B2E-D868-489E-A13A-7CBB1910EE91",
   CUBA: "4D78BDCE-E23B-4E16-80B9-E4B2DBBB231B",
   CYPRUS: "161B4086-4031-4A6A-8D23-3D71B9C55EA4",
   CZECHIA: "A1A8B1C0-F0EB-4BD7-B0B7-B8013404D962",
   DENMARK: "25179530-1978-4F09-B72A-4CB8B392D1CB",
   DJIBOUTI: "EE42FA11-F609-41F7-AA5E-57E1E474C76F",
   DOMINICA: "7FD0FA7E-D75A-4384-A0E0-92ACF57CC52A",
   DOMINICAN_REPUBLIC: "99CEFBD8-D318-448E-ACAB-420206B9A774",
   ECUADOR: "A6BF0B1A-18E7-446C-AE62-0ABA5EC432A6",
   EGYPT: "0216D4A5-D2BE-4E6D-847E-0E673C9D100B",
   EL_SALVADOR: "FEBBF19B-8A3B-4BA0-AE12-6305D0564898",
   EQUATORIAL_GUINEA: "468A25B8-7766-426B-92F8-F2DDE570F565",
   ERITREA: "91A8652B-6260-49C8-A59C-C78D2214CDC9",
   ESTONIA: "9DE08DBA-1EE9-412B-BB97-FB79AB2A2875",
   ESWATINI: "64E9B1A5-443C-45EB-98AF-A455AE64FFE8",
   ETHIOPIA: "B54CB65D-201E-459A-A2FB-C8AADFF6742F",
   FIJI: "F833D956-24CC-401A-B68C-64AD8FAE79C6",
   FINLAND: "6025A7C2-382A-48B8-9E45-FD40A24EE0E1",
   FRANCE: "0A777CEA-24E6-47EF-9E23-B12E4E15E6FC",
   GABON: "89D6F8E8-3687-4176-A61D-51A73494A6A3",
   GAMBIA: "DBB35FAA-D715-4075-8391-96677B360803",
   GEORGIA: "46D394AB-6C2B-48DC-95EE-6B25E57E8A36",
   GERMANY: "815B8B83-B40A-461E-922F-3C9C49EC0CE7",
   GHANA: "068C414E-CC64-4C1F-BE05-53D328575DE6",
   GREECE: "4B366739-D4AC-42B2-BDB6-FF835DB8C2C2",
   GRENADA: "FA39636D-843E-42D7-B4CC-04334CCFA2B5",
   GUATEMALA: "F3735256-5952-4923-B167-0FDC5751A6C0",
   GUINEA: "930767BD-AF77-4982-9211-C9EF0030A66A",
   GUINEA_BISSAU: "8F999EB1-B19A-4F4A-847E-5DA9ED30BA51",
   GUYANA: "B5DC09BF-14BE-4F07-A366-6E1D0FF045F1",
   HAITI: "2973986B-8207-4D9C-B60E-AC1229B5C836",
   HOLY_SEE: "F29C560D-1CCE-40F6-A5B3-F7A871E50A5F",
   HONDURAS: "A447D7BE-0E83-413B-8DEE-22F801E93469",
   HUNGARY: "A49330E5-658F-4D7B-BCC2-E2AB738EAA8B",
   ICELAND: "F5A0FABC-F99A-4183-9945-964228CB42F7",
   INDIA: "01EB4658-B1ED-41F0-8A15-22DBB43D1EB3",
   INDONESIA: "9F43A07A-8C32-450D-BD01-2994A1A45ED1",
   IRAN: "6AE2EEFD-B444-417F-9CE9-BF8087FEB176",
   IRAQ: "00A569DA-03AA-47BC-8238-4D5A5223D428",
   IRELAND: "E8541078-0A32-41C5-8EEF-D0C5C8934BC3",
   ISRAEL: "DFA44FE3-8E40-4EDC-BA9E-BDEF4EF59EE6",
   ITALY: "E3B1E3E9-4ACB-46E6-AF7E-26A76C0A912B",
   JAMAICA: "4A91F8B9-D1F1-4C8D-8C08-3C75590E0747",
   JAPAN: "3AAA2DE0-D151-44D9-8F52-73E21D2C2F7D",
   JORDAN: "FE1700A6-E9B3-48A8-A4B6-A373791D2AC6",
   KAZAKHSTAN: "BE419CA7-48CB-46C1-BA8B-8DFCAC967210",
   KENYA: "EB6FE506-F13B-460D-A8BE-352389DCF576",
   KIRIBATI: "6A1B081C-381E-401A-8ED4-DF7B41CF7E7D",
   KOREA_DEMOCRATIC_PEOPLES_REPUBLIC: "5C48C7F8-512D-4D66-A2AE-41549CF818AD",
   KOREA_REPUBLIC: "A70C64FC-C3E6-4D0D-8DAD-E816C5ABFCBF",
   KOSOVO: "AA8E2105-35CD-47D6-938E-A5F2308526A9",
   KUWAIT: "57C708DA-DCC2-4249-AECB-73E5C4CF5524",
   KYRGYZSTAN: "9164EBE3-BEE6-4A57-9A8E-ADCC224DF2F5",
   LAO_PEOPLES_DEMOCRATIC_REPUBLIC: "D355FA56-66C5-497A-B15C-9E1756C08E2E",
   LATVIA: "B4784E97-FF94-4EC0-B903-3DAF2B711BC5",
   LEBANON: "E49B4675-33F8-4CF1-816A-54264D1D3768",
   LESOTHO: "7BBEC497-84B4-4FDD-A2AA-FC1DA3BCFF33",
   LIBERIA: "F92E4A26-2257-4BFD-B621-AB8892EF4889",
   LIBYA: "16AF2F43-D8C4-4008-91E2-349FE93297EE",
   LIECHTENSTEIN: "ECE2029B-C070-4F04-98E4-D1F99ECBD64C",
   LITHUANIA: "B7568F6D-0B92-4299-A95F-816E102A98CA",
   LUXEMBOURG: "40E9ED41-A44A-4BB7-BBAC-0B0CCE8DFCCD",
   MADAGASCAR: "E1790244-E5E6-4B14-9C81-352E687B2E43",
   MALAWI: "6C0B80D8-24FE-4A3B-AC87-625E7B7B0374",
   MALAYSIA: "9FB736E2-794F-4F7E-8AFA-01ACECC7745D",
   MALDIVES: "B55D42DA-9BD6-4BF4-A088-9BBC6721571F",
   MALI: "454DABC8-71CD-47EF-B549-F2CE78F9D7C3",
   MALTA: "EFA5B338-68FB-483E-80AD-202414548669",
   MARSHALL_ISLANDS: "9BED3898-7AB5-4ADD-BCDA-FD24EA3A874D",
   MAURITANIA: "9C14D1D9-6E0C-4F47-B897-5586EA86B853",
   MAURITIUS: "AB3A9638-B9E5-4E0E-9689-A4A7BE304FC1",
   MEXICO: "5687BEB0-B932-47C0-B176-9B25180DE1F0",
   MICRONESIA: "7F643929-ADDE-41CF-AB13-1159E283E7C6",
   MOLDOVA: "ED562B64-F67C-45D8-BCB2-8AFA78E9B3B3",
   MONACO: "4ABAF1B2-01D1-44AC-8D0E-3D152D439AD6",
   MONGOLIA: "7BAD235B-0F0D-4428-A721-3179B35FB4B2",
   MONTENEGRO: "AA103AD5-1754-4645-9405-7EC795B1113B",
   MOROCCO: "5ED528B4-67C2-47B8-BB64-004415D6F602",
   MOZAMBIQUE: "3AF250AD-725D-4253-BB3B-6C2DAE1EFB68",
   MYANMAR: "EAB1BC4E-614D-44B5-A31F-D9CCBF0885F6",
   NAMIBIA: "619C3FE3-5213-4124-B220-8D4DE8E07DCA",
   NAURU: "D037A7E9-4A15-43A6-A759-77A487D19763",
   NEPAL: "3BA4F260-B8B4-423B-B404-E596F75869E9",
   NETHERLANDS: "E91AFE7F-0B87-40BE-BFF0-C19E07D0CC96",
   NEW_ZEALAND: "3F65DF6B-2B7E-400B-BFC0-667C07AB0548",
   NICARAGUA: "EC889501-454E-494D-960D-10BED61C1EBB",
   NIGER: "B718B97F-2179-4920-8E11-D4E3897FE360",
   NIGERIA: "CA9B3FF2-3F56-4626-A363-1806FC7AC2BF",
   NORWAY: "167990F3-901F-4CC3-9D1D-CD553CF9297F",
   OMAN: "BD22DAB3-3BA6-40DD-A44D-B2B9D4FB3386",
   PAKISTAN: "21CDE846-85B4-44F2-971D-E44A90673756",
   PALAU: "521B763D-586B-4AE1-8CC0-E7A726553EDA",
   PALESTINE: "7CA006DC-B5D3-43E6-A441-FBDEA2273FBB",
   PANAMA: "7267C04A-ACB5-47EA-90DA-CA9BEBD02822",
   PAPUA_NEW_GUINEA: "AA3B9E13-93E2-4825-9495-E8C4AD9E0842",
   PARAGUAY: "CA1743CA-65C7-4E44-A8ED-E334AF8109EC",
   PERU: "C809437A-05B8-48ED-A770-EF80C51D8D10",
   PHILIPPINES: "88CBE4EA-8BD2-463E-8438-0E1B145BCE81",
   POLAND: "A7D9D0B7-0880-4C47-8A8E-28E4B9E3599A",
   PORTUGAL: "3174534B-354E-4005-955A-F2602607DC26",
   QATAR: "2B63A22C-44DC-451D-B6FB-B1168F18E21D",
   REPUBLIC_OF_NORTH_MACEDONIA: "003951C2-0423-4C5C-8FA2-A6AFEB1A339B",
   ROMANIA: "CAB4ABE2-3EE5-44DF-A1BF-23475608CAB1",
   RUSSIAN_FEDERATION: "F5B32DD4-4DF2-4648-9E0A-7263AC02EDBE",
   RWANDA: "13984D2B-BE91-4BD6-9E17-E2AB99C05731",
   SAINT_KITTS_AND_NEVIS: "3DF1D758-A7F6-4D53-924D-F45B23BF65B3",
   SAINT_LUCIA: "FFADDCF6-309A-477D-A902-56AB9A38F6AD",
   SAINT_VINCENT_AND_THE_GRENADINES: "ABDD8168-5DE2-4A2A-8232-45DB1844B443",
   SAMOA: "E4683E8D-361A-46AD-8EA5-60541D96CD25",
   SAN_MARINO: "7261820E-B486-4937-A461-F98ABE9BBC62",
   SAO_TOME_AND_PRINCIPE: "D268E87A-3AFA-42BA-9774-78AC7DF54DC8",
   SAUDI_ARABIA: "9BE0A508-74CB-47AE-B6E6-13BFBDDA3F32",
   SENEGAL: "8DC06194-4F11-4785-8BF2-A37735C3F49B",
   SERBIA: "83F1FCA3-81CD-4BB9-A56F-65A774D61A20",
   SEYCHELLES: "1D00A5F2-1065-4F99-822D-B25A9516FAA4",
   SIERRA_LEONE: "E03E5D2A-2B03-4A07-B4D0-24EE6957BDCE",
   SINGAPORE: "5CA893AC-27A2-4493-B448-878174CDE542",
   SLOVAKIA: "7DD763ED-EA42-4D53-9F21-622A78B07C09",
   SLOVENIA: "54A00C04-8BBF-4BCF-B641-0E11621004A2",
   SOLOMON_ISLANDS: "357B1E6E-A05B-42EF-A330-75F4C89DA9B7",
   SOMALIA: "F2F4062E-65EC-4BB8-91E9-A1023859CCB6",
   SOUTH_AFRICA: "B2D0B01A-E18D-4DA7-928B-F701BD393AB4",
   SOUTH_SUDAN: "75D629CA-4E8A-409C-B00F-62D2FA434226",
   SPAIN: "98254316-911F-4DE7-AD1F-C264D0FC6741",
   SRI_LANKA: "B42CE75F-9DFD-495C-AF09-4CEB3972F2F4",
   SUDAN: "5FFF7FB3-D6B3-4967-B513-EA9597B51E6C",
   SURINAME: "B2463E57-0E81-4FB2-A0B1-92499238977D",
   SWEDEN: "3D0BF8C9-724E-4460-A352-D42215BA9EBF",
   SWITZERLAND: "07927A28-FB3D-44F3-99E5-AB02D46B9B3B",
   SYRIAN_ARAB_REPUBLIC: "12FC3310-CFB0-435B-B6D4-0D031AC8C3B1",
   TAJIKISTAN: "6B9939CA-30F7-4870-9C3A-B12EAFBEFE56",
   TANZANIA_UNITED_REPUBLIC: "B3E32076-7EF9-4B14-A0BC-65D84FDD4717",
   THAILAND: "7FAB386D-D2F1-40D8-AE30-2391D60AAAAA",
   TIMOR_LESTE: "D3CF2A02-15F1-4E8F-BC2D-1E9D78B9F801",
   TOGO: "FA8FFB26-771C-476A-BF69-FBE096049F8F",
   TONGA: "54D3C55A-2DBC-4312-BC3A-3D599E68B890",
   TRINIDAD_AND_TOBAGO: "4C20D49D-B539-4E73-8E91-A966DA51EBC8",
   TUNISIA: "388019A0-2393-476B-A1E1-EFF92BF19D7E",
   TURKEY: "FB99F4E4-CE8A-402A-AA38-A317C57D6BEB",
   TURKMENISTAN: "16A73E01-2771-4D30-9F73-774FC1F4560A",
   TUVALU: "BAC4417E-F3ED-4D8A-9C05-08518F4C963D",
   UGANDA: "953D7074-068B-4F41-B6C7-C11A1E086DDA",
   UKRAINE: "4AA142C9-B9BB-4C58-A8AD-EAFC7E50C19D",
   UNITED_ARAB_EMIRATES: "B7B49B06-0A27-4BDF-BCA3-F9C04DD37F4B",
   UNITED_KINGDOM: "386DE976-5116-410B-BE5E-080F8DA96341",
   UNITED_STATES: "05F4E11E-A6FD-4095-A739-BE7375954132",
   URUGUAY: "E875EF3C-7BE5-4C16-92F9-3C21160FAAE4",
   UZBEKISTAN: "E9EB0696-F708-463D-BAFE-34E5F35F49BC",
   VANUATU: "946D8343-4288-4F56-9518-0496ABE34550",
   VENEZUELA: "BA39ABFF-3723-47B4-9F38-E694B4B3EF16",
   VIET_NAM: "C8E067A5-4512-48B0-A405-D67EF13EA44C",
   YEMEN: "77E5A32A-96DD-4599-B190-642DE3B1CEAE",
   ZAMBIA: "70A81239-4D08-40AC-99E4-1850E91EAA19",
   ZIMBABWE: "3B5B3F60-595B-4DF8-A284-AF305B45E96D"
});
