import React, { useEffect, useState } from "react";
import { Spin, Button, Col, Form, Input, Row, Select, notification } from "antd";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { usePersonalDataStore, useProfileStore } from "../../pages/auth/authStore";
import { UserRolesEnum } from "../../pages/auth/userRoles.enum";
import { useStateChanges } from "../../base/hooks/useStateChanges";
import { useCountriesAndData } from "../../base/hooks/useCountriesAndData";
import AuthService from "../../base/services/authentication.service";
import { httpService } from "../../base/services/httpService.service";
import { LocalStorageService } from "../../base/services/local-storage.service";

const PersonalInformation = React.memo(({ handleUserData }) => {
   const personalData = usePersonalDataStore((state) => state.personalData);
   const setPersonalData = usePersonalDataStore((state) => state.setPersonalData);
   const user = AuthService.getUser();
   const organizationId = AuthService.getOrganizationId();
   const [form] = Form.useForm();
   const [organizationTypes, setOrganizationTypes] = useState();
   const { countries, selectedCountry, states, handleCountryChange } = useCountriesAndData(form);
   const { cities, handleStateChange } = useStateChanges(selectedCountry, form);
   const { isLoading, formData } = useProfileStore();
   const [isSavingData, setIsSavingData] = useState(false);

   useEffect(() => {
      httpService.get(`/Account/getOrganizationTypes`, (res) => {
         try {
            setOrganizationTypes(res.data);
         } catch (error) {
            console.log(error);
         }
      });
   }, []);

   const onCountryChange = (selectedOption) => {
      handleCountryChange(selectedOption, {
         governorateOrProvince: null,
         cityOrCampOrVillage: null
      });
   };

   const onStateChange = (selectedOption) => {
      handleStateChange(selectedOption, { cityOrCampOrVillage: null });
   };

   const updatePersonalInformation = (values) => {
      setIsSavingData(true);
      const payload = {
         userId: user.id,
         userFullName: values.userFullName,
         gender: values.gender,
         userRoleWithinOrganization: values.userRoleWithinOrganization,
         userPhoneNumber: values.userPhoneNumber,
         userCountryId: values.userCountryId,
         geographicalDetail: {
            governorateOrProvince: values.governorateOrProvince, //state
            district: values.district,
            subdistrict: values.subdistrict,
            cityOrCampOrVillage: values.cityOrCampOrVillage, //city
            neighborhoodOrSector: values.neighborhoodOrSector, //Village/Neighborhood
            houseOrTentDetails: values.houseOrTentDetails //Address line
         },
         organizationId: organizationId,
         organizationName: values.organizationName,
         organizationTypeId: values.organizationTypeId,
         organizationCountryId: values.organizationCountryId,
         zipCode: values.zipCode
      };

      httpService
         .post(`/Organizations/editUserAndOrganization`, payload, (response) => {
            if (!response) return;
            LocalStorageService.setItem("organizationName", values.organizationName);
            LocalStorageService.setItem("user", { ...user, fullName: values.userFullName });
            handleUserData();
            notification.open({
               type: "success",
               message: response.data.message || "Your information has been updated successfully."
            });
            setPersonalData({
               ...personalData,
               fullName: values.userFullName
            });
         })
         .catch((error) => {
            notification.error({
               message: error.response?.data?.message || "Something went wrong. Please try again."
            });
         })
         .finally(() => {
            setIsSavingData(false);
         });
   };

   return (
      <>
         {isLoading ? (
            <Spin
               style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "50px"
               }}
            ></Spin>
         ) : (
            <Form
               form={form}
               className="personalInformation"
               initialValues={formData}
               onFinish={updatePersonalInformation}
               autoComplete="off"
               layout="vertical"
            >
               <div className="mt-8 mb-5">
                  <h4>Personal information</h4>
               </div>
               <Row gutter={24}>
                  <Col xs={24} sm={12}>
                     <Form.Item
                        name="userFullName"
                        label={<span className="label-text required">Full Name</span>}
                        rules={[{ required: true, message: "Please enter your fullname" }]}
                     >
                        <Input placeholder="Fullname" />
                     </Form.Item>
                  </Col>
                  <Col xs={24} sm={12}>
                     <Form.Item
                        name="userRoleWithinOrganization"
                        label={<span className="label-text">Role within Organization</span>}
                     >
                        <Input
                           disabled
                           placeholder="Specify your role within the organization..."
                        />
                     </Form.Item>
                  </Col>
                  {!AuthService.isAuthorized([UserRolesEnum.Organization]) ? (
                     <Col xs={24} sm={12}>
                        <Form.Item name="gender" label={<span className="label-text">Gender</span>}>
                           <Select
                              placeholder="Select an option"
                              options={[
                                 {
                                    value: "Male",
                                    label: "Male"
                                 },
                                 {
                                    value: "Female",
                                    label: "Female"
                                 },
                                 {
                                    value: "Other",
                                    label: "Other"
                                 }
                              ]}
                           />
                        </Form.Item>
                     </Col>
                  ) : null}
                  <Col xs={24} sm={12}>
                     <Form.Item
                        label={<span className="label-text required">Phone number</span>}
                        name="userPhoneNumber"
                        rules={[
                           {
                              pattern: /^\d+$/,
                              required: true,
                              message: "Phone number can only contain numbers"
                           }
                        ]}
                     >
                        <PhoneInput
                           country={"us"}
                           rules={[
                              {
                                 required: true,
                                 message:
                                    "Oops! It seems like there's an issue with the phone number you entered. Please ensure it follows the correct format, such as e.x(555) 123-4567, and try again."
                              }
                           ]}
                        />
                     </Form.Item>
                  </Col>
               </Row>
               <div className="mt-8 mb-5">
                  <h4>Location</h4>
               </div>
               <Row gutter={24}>
                  <Col xs={24} sm={12}>
                     <Form.Item
                        name="userCountryId"
                        label={<span className="label-text required">Country</span>}
                        rules={[{ required: true, message: "Please enter country" }]}
                     >
                        <Select
                           placeholder="Select an option"
                           showSearch
                           allowClear
                           filterOption={(input, option) => {
                              return (option?.label ?? "")
                                 .toLowerCase()
                                 .startsWith(input.toLowerCase());
                           }}
                           onChange={(selectedCountry) => {
                              const selectedCountryName = countries.find(
                                 (country) => country.id === selectedCountry
                              );
                              if (selectedCountryName) {
                                 onCountryChange(selectedCountryName);
                              }
                           }}
                           options={countries.map((item) => ({
                              value: item.id,
                              label: item.name
                           }))}
                        />
                     </Form.Item>
                  </Col>
                  <Col xs={24} sm={12}>
                     <Form.Item
                        name="governorateOrProvince"
                        label={<span className="label-text required">State</span>}
                        rules={[{ required: true, message: "Please select state" }]}
                     >
                        <Select
                           placeholder="Select an option"
                           showSearch
                           allowClear
                           filterOption={(input, option) => {
                              return (option?.label ?? "")
                                 .toLowerCase()
                                 .startsWith(input.toLowerCase());
                           }}
                           onChange={(selectedState) => {
                              const selectedStateName = states.find(
                                 (state) => state.name === selectedState
                              );
                              if (selectedState) {
                                 onStateChange(selectedStateName);
                              }
                           }}
                           options={states.map((item) => ({
                              value: item.name,
                              label: item.name
                           }))}
                        />
                     </Form.Item>
                  </Col>
                  <Col xs={24} sm={12}>
                     <Form.Item
                        name="district"
                        label={<span className="label-text">District</span>}
                     >
                        <Input placeholder="Type down the district" />
                     </Form.Item>
                  </Col>
                  <Col xs={24} sm={12}>
                     <Form.Item
                        name="subdistrict"
                        label={<span className="label-text">Subdistrict</span>}
                     >
                        <Input placeholder="Type down the subdistrict" />
                     </Form.Item>
                  </Col>
                  <Col xs={24} sm={12}>
                     <Form.Item
                        name="cityOrCampOrVillage"
                        label={
                           <span className={`label-text ${cities.length > 0 ? "required" : ""} `}>
                              City
                           </span>
                        }
                        rules={[{ required: cities.length > 0, message: "Please select a city" }]}
                     >
                        <Select
                           placeholder="Select an option"
                           showSearch
                           allowClear
                           filterOption={(input, option) => {
                              return (option?.label ?? "")
                                 .toLowerCase()
                                 .startsWith(input.toLowerCase());
                           }}
                           options={cities.map((item) => ({
                              value: item.name,
                              label: item.name
                           }))}
                        />
                     </Form.Item>
                  </Col>
                  <Col xs={24} sm={12}>
                     <Form.Item
                        name="neighborhoodOrSector"
                        label={<span className="label-text">Village/Neighborhood</span>}
                     >
                        <Input placeholder="Type down the village or neighborhood" />
                     </Form.Item>
                  </Col>
                  <Col xs={24} sm={12}>
                     <Form.Item
                        name="houseOrTentDetails"
                        label={<span className="label-text required">Address line</span>}
                        rules={[{ required: true, message: "Please enter address" }]}
                        required
                     >
                        <Input placeholder="Address line" />
                     </Form.Item>
                  </Col>
                  <Col xs={24} sm={12}>
                     <Form.Item name="zipCode" label={<span className="label-text">ZIP code</span>}>
                        <Input type="number" placeholder="ZIP code" />
                     </Form.Item>
                  </Col>
               </Row>
               <div className="mt-8 mb-5">
                  <h4>Company information</h4>
               </div>
               <Row gutter={24}>
                  <Col xs={24} sm={12}>
                     <Form.Item
                        name="organizationName"
                        label={<span className="label-text required">Organization's name</span>}
                        rules={[
                           {
                              required: AuthService.isAuthorized([UserRolesEnum.Organization]),
                              message: "Please input organization name"
                           }
                        ]}
                     >
                        <Input
                           placeholder="Organization name"
                           disabled={!AuthService.isAuthorized([UserRolesEnum.Organization])}
                        />
                     </Form.Item>
                  </Col>
                  <Col xs={24} sm={12}>
                     <Form.Item
                        name="organizationTypeId"
                        label={<span className="label-text required">Type of organization</span>}
                        rules={[
                           {
                              required: AuthService.isAuthorized([UserRolesEnum.Organization]),
                              message: "Please select an option"
                           }
                        ]}
                     >
                        <Select
                           placeholder="Select an option"
                           options={organizationTypes?.map((item) => ({
                              value: item.id,
                              label: item.name
                           }))}
                           disabled={!AuthService.isAuthorized([UserRolesEnum.Organization])}
                        />
                     </Form.Item>
                  </Col>
                  <Col xs={24} sm={12}>
                     <Form.Item
                        name="organizationCountryId"
                        label={<span className="label-text required">Country</span>}
                        rules={[
                           {
                              required: AuthService.isAuthorized([UserRolesEnum.Organization]),
                              message: "Please select an option"
                           }
                        ]}
                     >
                        <Select
                           placeholder="Select an option"
                           showSearch
                           allowClear
                           filterOption={(input, option) => {
                              return (option?.label ?? "")
                                 .toLowerCase()
                                 .startsWith(input.toLowerCase());
                           }}
                           options={countries.map((item) => ({
                              value: item.id,
                              label: item.name
                           }))}
                           disabled={!AuthService.isAuthorized([UserRolesEnum.Organization])}
                        />
                     </Form.Item>
                  </Col>
               </Row>
               {/* <div className="mt-8 mb-5">
            <h4>Notification preferences</h4>
         </div>
         <Col xs={24} sm={24} className="gutter-row mb-5" span={24}>
            <Switch size="small" /> Allow email notifications
         </Col>
         <Col xs={24} sm={24} className="gutter-row" span={24}>
            <Switch size="small" /> WhatsApp notifications
         </Col> */}

               <Row gutter={16} className="mt-8" align="middle">
                  <Col>
                     <Button className="defaultButton">Cancel</Button>
                  </Col>
                  <Col>
                     <Button htmlType="submit" className="primaryButton" loading={isSavingData}>
                        Save changes
                     </Button>
                  </Col>
               </Row>
            </Form>
         )}
      </>
   );
});

export default PersonalInformation;
