import React, { useEffect } from "react";
import { InputNumber } from "antd";
import { MinusOutlined, PlusOutlined } from "@ant-design/icons";
import PreviewQuestionLabel from "../../../common/PreviewQuestionLabel/PreviewQuestionLabel";
import { useSurveyInputStore } from "../../../../surveyStore";
import useEvaluateConstraint from "../../question-preview/hooks/useEvaluateConstraint";
import PreviewQuestionConstraints from "../../../common/PreviewQuestionConstraints/PreviewQuestionConstraints";

import "./Number.scss";

export const Number = React.memo(
   ({ props, hasErrors, surveyId, userId, submissionId, label, hint, defaultValue }) => {
      const { id, questionType, isRequired, answers } = props;

      const inputState = useSurveyInputStore((state) => state.inputs?.[id]);
      const setInputs = useSurveyInputStore((state) => state.setInputs);

      const hasSubmissionId = Boolean(submissionId);

      const submissionAnswer = hasSubmissionId
         ? answers?.find((answer) =>
              props.repeatInstanceIndex
                 ? answer?.answerDetail.repeatGroup === props.repeatInstanceIndex
                 : answer
           )?.answerDetail?.number
         : null;

      const value = hasSubmissionId ? submissionAnswer : inputState?.answerDetail?.number ?? null;

      const passConstraint = useEvaluateConstraint(
         !hasSubmissionId ? props.constraint : null,
         value
      );

      const showErrors =
         hasErrors && isRequired && (!inputState || inputState?.answerDetail?.number === null);

      const onChange = (e) => {
         if (submissionId) {
            return;
         }
         if (e !== 0 && !e) {
            setInputs({
               target: {
                  name: id,
                  value: ""
               }
            });
         } else {
            setInputs({
               target: {
                  name: id,
                  value: {
                     surveyId: surveyId,
                     userId: userId,
                     questionId: id,
                     questionType: questionType,
                     name: props.name,
                     skipLogicAnswer: e,
                     answerDetail: {
                        number: e
                     }
                  }
               }
            });
         }
      };

      const onKeyDown = (event) => {
         if (event.key === "." || event.key === ",") {
            event.preventDefault();
         }
      };

      useEffect(() => {
         if (defaultValue) {
            onChange(defaultValue);
         }
         // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [defaultValue]);

      return (
         <div className="number-submission-type-wrapper">
            <PreviewQuestionLabel label={label} isRequired={isRequired} hint={hint} />
            <InputNumber
               size="large"
               type="number"
               name={id}
               min={0}
               step={1}
               onKeyDown={onKeyDown}
               readOnly={hasSubmissionId}
               status={showErrors || (passConstraint !== null && !passConstraint) ? "error" : ""}
               placeholder={"Type your answer here"}
               value={value}
               onChange={onChange}
               controls={{
                  downIcon: <MinusOutlined style={{ fontSize: 16 }} />,
                  upIcon: <PlusOutlined style={{ fontSize: 16 }} />
               }}
               prefixCls="number-submission-type-wrapper--number"
            />

            <span className="number-submission-type-wrapper--error">
               {showErrors ? "This field is required" : " "}
               {passConstraint !== null && !passConstraint ? (
                  <PreviewQuestionConstraints constraintMessages={props.constraintMessages} />
               ) : null}
            </span>
         </div>
      );
   }
);
