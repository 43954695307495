import React, { useEffect, useMemo, useState } from "react";
import { Input } from "antd";
import PreviewQuestionLabel from "../../../common/PreviewQuestionLabel/PreviewQuestionLabel";
import { useSurveyInputStore } from "../../../../surveyStore";
import PreviewQuestionConstraints from "../../../common/PreviewQuestionConstraints/PreviewQuestionConstraints";

import "./Matrix.scss";

const { TextArea } = Input;

export const Matrix = React.memo(
   ({ props, hasErrors, surveyId, userId, submissionId, label, hint }) => {
      const { id, selectQuestionChoices, secondChoices, questionType, isRequired } = props;

      const hasSubmissionId = Boolean(submissionId);

      const [value, setValue] = useState(
         hasSubmissionId
            ? props.answers.find((answer) =>
                 props.repeatInstanceIndex
                    ? answer?.answerDetail.repeatGroup === props.repeatInstanceIndex
                    : answer
              )?.answerDetail?.answerMatrices || []
            : []
      );

      const inputState = useSurveyInputStore((state) => state.inputs?.[id]);
      const setInputs = useSurveyInputStore((state) => state.setInputs);

      const showErrors =
         hasErrors &&
         isRequired &&
         (!inputState || inputState?.answerDetail?.answerMatrix?.length === 0);

      const rowQuestions = useMemo(
         () =>
            selectQuestionChoices.map((element) => {
               return {
                  choice: element.choiceLabels[0]?.text,
                  id: element.choiceLabels[0]?.choiceId
               };
            }),
         [selectQuestionChoices]
      );

      const columnQuestions = useMemo(
         () =>
            secondChoices.map((element) => {
               return { choice: element.name, id: element.id };
            }),
         [secondChoices]
      );

      const onChange = (event) => {
         const { name, value: inputValue } = event.target;
         const splitString = name.split("===");
         const choiceId = splitString[0];
         const secondChoiceId = splitString[1];

         const existingIndex = value.findIndex(
            (el) => el.choiceId === choiceId && el.secondChoiceId === secondChoiceId
         );

         if (inputValue === "") {
            if (existingIndex !== -1) {
               setValue((prev) => {
                  const updatedValue = [...prev];
                  updatedValue.splice(existingIndex, 1);
                  return updatedValue;
               });
            }
         } else {
            if (existingIndex !== -1) {
               setValue((prev) => {
                  const updatedValue = [...prev];
                  updatedValue[existingIndex] = { choiceId, secondChoiceId, text: inputValue };
                  return updatedValue;
               });
            } else {
               setValue((prev) => [...prev, { choiceId, secondChoiceId, text: inputValue }]);
            }
         }
      };

      useEffect(() => {
         if (hasSubmissionId) {
            return;
         }

         setValue(inputState?.answerDetail?.answerMatrix ?? []);
      }, [inputState, id, hasSubmissionId]);

      useEffect(() => {
         if (hasSubmissionId) {
            return;
         }
         if (value.length === 0) {
            return;
         }
         setInputs({
            target: {
               name: id,
               value: {
                  surveyId: surveyId,
                  userId: userId,
                  questionId: id,
                  questionType: questionType,
                  name: props.name,
                  skipLogicAnswer: value,
                  answerDetail: {
                     answerMatrix: value
                  }
               }
            }
         });
         // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [value, hasSubmissionId]);

      return (
         <div className="matrix-submission-type">
            <PreviewQuestionLabel label={label} isRequired={isRequired} hint={hint} />
            <PreviewQuestionConstraints constraintMessages={props.constraintMessages} />

            <div className="matrix-submission-type--wrapper">
               <table className="matrix-submission-type--wrapper-table">
                  <thead>
                     <tr>
                        {[{}, ...columnQuestions].map((column, index) => (
                           <th
                              key={`column-${index}`}
                              style={{
                                 width: `${100 / (columnQuestions.length + 1)}%`,
                                 minWidth: "200px"
                              }}
                           >
                              {column.choice}
                           </th>
                        ))}
                     </tr>
                  </thead>
                  <tbody className={showErrors ? "error" : ""}>
                     {rowQuestions.map((row, index) => (
                        <tr key={index} className={showErrors ? "error" : ""}>
                           <td className={showErrors ? "error" : ""} style={{ minWidth: "200px" }}>
                              {row.choice}
                           </td>

                           {columnQuestions.map((column, columnIndex) => (
                              <td
                                 key={`row-${index}-column-${columnIndex}`}
                                 className={showErrors ? "error" : ""}
                              >
                                 <TextArea
                                    autoSize={{ minRows: 4 }}
                                    onChange={onChange}
                                    name={`${row.id}===${column.id}`}
                                    placeholder="Type here..."
                                    value={
                                       value.find(
                                          (el) =>
                                             el.choiceId === row.id &&
                                             el.secondChoiceId === column.id
                                       )?.text
                                    }
                                    readOnly={hasSubmissionId}
                                    prefixCls="matrix-submission-type--wrapper-table_textarea"
                                 />
                              </td>
                           ))}
                        </tr>
                     ))}
                  </tbody>
               </table>
            </div>

            <span className="rating-submission-type--error">
               {showErrors ? "This field is required" : " "}
            </span>
         </div>
      );
   }
);
