import React, { Fragment, useEffect, useMemo, useRef, useState } from "react";
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { editDataStore, useSurveyInputStore, useTranslateStore } from "../../surveyStore";

import Section from "../../components/section/Section";
import ItemSelector from "../../components/question/question-preview/ItemSelector";
import AuthService from "../../../../../base/services/authentication.service";
import { LocalStorageService } from "../../../../../base/services/local-storage.service";
import { Button, Input, Modal, message, Spin, Empty, notification, Alert } from "antd";
import { answerPayload, getGeolocation } from "../../helpers";
import dayjs from "dayjs";
import https from "../../../../../base/utils/http";

import { SendOutlined } from "@ant-design/icons";
import SurveyConfirmation from "../SurveySubmissions/SurveyConfirmation";
import DraftList, { START_NEW_DRAFT } from "./PreviewComponents/DraftList";
import { Constants, isPublicSurveyRoute } from "../../../../../utils/constants";
import useOnlineStore from "../../../../../utils/useOnlineStore";
import PreviewBanner from "./PreviewComponents/PreviewBanner";
import PreviewLanguage from "./PreviewComponents/PreviewLanguage";
import { useFormik } from "formik";
import PreviewModal from "./PreviewComponents/PreviewModal";
import PreviewActions from "./PreviewComponents/PreviewActions";
import { InputType, SurveyStatusEnum } from "../../../../../utils/enums";
import { withErrorBoundary } from "../../../../../components/ErrorBoundary/withErrorBoundary";
import { httpService } from "../../../../../base/services/httpService.service";
import usePrompt from "../../../../../utils/usePrompt";
import {
   saveDraft,
   loadDraft,
   getAllDraftsForSurvey,
   deleteDraft,
   db_drafts
} from "../../../../../storage/db";
import { useLiveQuery } from "dexie-react-hooks";

import "./preview.scss";

const SurveyDataItems = React.memo(({ surveyData, hasErrors, submissionId }) => {
   return (
      <div className="preview-survey-section">
         {surveyData?.map((item, i) => {
            if (item.itemLabel === "Section") {
               return (
                  <Section
                     key={i}
                     hasErrors={hasErrors}
                     submissionId={submissionId}
                     className="survey-section"
                     surveyItem={item}
                  />
               );
            } else {
               const isPreviousItemSection = surveyData[i - 1]?.itemLabel === "Section";
               const invisibleQuestions = [InputType.CALCULATE_QUESTION, InputType.HIDDEN].includes(
                  item?.surveyItem?.questionType
               );

               return (
                  <div
                     className={`preview-survey-section--questions_wrapper ${
                        isPreviousItemSection && !invisibleQuestions ? "extra-padding" : ""
                     } ${invisibleQuestions ? "p-0" : ""}`}
                     key={i}
                  >
                     <ItemSelector
                        hasErrors={hasErrors}
                        submissionId={submissionId}
                        props={item.surveyItem}
                     />
                  </div>
               );
            }
         })}
      </div>
   );
});

const getInputsState = () => {
   const { inputs } = useSurveyInputStore.getState();
   return inputs;
};

function Preview() {
   const { id, uuid } = useParams();
   const userId = AuthService.getUser()?.id;
   const location = useLocation();
   const navigate = useNavigate();
   const submissionId = location.state;

   const componentRef = useRef();
   const isSubmitting = useRef(false);
   const [modalApi, modalContextHolder] = Modal.useModal();

   const isOnline = useOnlineStore((state) => state.isOnline);

   const getData = editDataStore((s) => s.fetchSurveyById);
   const surveyData = editDataStore((state) => state.surveyData);
   const loading = editDataStore((state) => state.isSurveyLoading);
   const surveyAlldata = editDataStore((state) => state.surveyAllData);
   const surveyBaseLanguageName = editDataStore((state) => state.surveyBaseLanguageName);

   const setDraftSurvey = useSurveyInputStore((state) => state.setDraftSurvey);
   const inputs = useSurveyInputStore((state) => state.inputs);
   const resetInputs = useSurveyInputStore((state) => state.resetInputs);
   const setInputs = useSurveyInputStore((state) => state.setInputs);

   const [isModalOpen, setIsModalOpen] = useState(false);
   const [isSaveAsDraft, setIsSaveAsDraft] = useState(false);
   const [selectedDraft, setSelectedDraft] = useState(null);
   const [load, setLoad] = useState(false);
   const [surveyStatusUpdated, setSurveyStatusUpdate] = useState(false);
   const [submittingAllDrafts, setSubmittingAllDrafts] = useState(false);

   const [hasErrors, setHasErrors] = useState(false);
   const { toLanguage } = useTranslateStore();
   const [startTimeEnd, setStartTimeEnd] = useState(null);

   const [searchParams] = useSearchParams();
   const taskId = searchParams.get("taskId");
   const receiverId = searchParams.get("receiverId");
   const [assignedTaskIdsForSurvey, setAssignedTaskIdsForSurvey] = useState([]);

   const [hasSurveyExpired, setHasSurveyExpired] = useState();

   // indexedDB live queries
   const draftCount = useLiveQuery(() => db_drafts.where({ surveyId: id }).count());

   const draftFormik = useFormik({
      initialValues: {
         draftTitle: "",
         draftDesc: ""
      }
   });
   const { draftTitle, draftDesc } = draftFormik.values;
   const isEditingSurvey = surveyAlldata?.surveyStatusId === SurveyStatusEnum.Editing;

   const inputValues = useMemo(() => {
      const surveyQuestions = editDataStore.getState().surveyQuestions;

      return Object.values(inputs).filter((inputItem) => {
         const question = surveyQuestions.find((q) => q.name === inputItem.name);

         if (
            question &&
            (question.default !== null ||
               [InputType.NOTE, InputType.CALCULATE_QUESTION, InputType.HIDDEN].includes(
                  question.questionType
               ))
         ) {
            return false;
         }

         return true;
      });
   }, [inputs]);

   usePrompt(
      "You have unsaved changes, are you sure you want to leave?",
      !isSubmitting.current && inputValues?.length !== 0
   );

   function clearLocalStorageKeys(keysToClear) {
      keysToClear.forEach((key) => {
         LocalStorageService.removeItem(key); // Remove only the specified keys
      });
   }

   // Explanation: this clears up localStorage keys,
   // which means will log out the user that is logged in in that browser,
   // in order to not submit the Public Survey with the account that is logged in currently
   if (isPublicSurveyRoute() && !taskId) {
      clearLocalStorageKeys([
         "user",
         "tokenExpire",
         "atoken",
         "organizationId",
         "organizationName",
         "roles"
      ]);
   }

   useEffect(() => {
      getAllDraftsForSurvey(id).then((drafts) => {
         const draftsLength = drafts.length;
         setIsModalOpen(draftsLength > 0);
      });
   }, [id]);

   // TODO: add navigation blocking for unsaved changes
   useEffect(() => {
      return () => {
         setInputs();
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   useEffect(() => {
      const hasExpired = dayjs().isAfter(dayjs(surveyAlldata?.endDate));
      setHasSurveyExpired(hasExpired);
   }, [surveyAlldata?.endDate]);

   useEffect(() => {
      getData({
         surveyId: id,
         languageId: toLanguage === "" ? Constants.ENGLISH_LANGUAGE_ID : toLanguage,
         taskId,
         receiverId
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [id, toLanguage]);

   useEffect(() => {
      if (isOnline && taskId) {
         const params = new URLSearchParams({
            surveyId: id,
            userId: userId
         });

         httpService.get(
            `Task/GetAssignedTaskIdsForSurveyAndDataCollector?${params.toString()}`,
            (res) => {
               if (res.data?.length > 0) {
                  setAssignedTaskIdsForSurvey(res.data);
               }
            },
            (_error) => {
               console.log("_error:", _error);
            }
         );
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [isOnline, taskId]);

   useEffect(() => {
      LocalStorageService.setItem("startTime", dayjs());
      setStartTimeEnd(dayjs());

      if (surveyAlldata.toggleLocation) {
         getGeolocation()
            .then((geolocation) => {
               LocalStorageService.setItem(`geolocation`, geolocation);
            })
            .catch((error) => {
               console.error("Error getting geolocation:", error);
               LocalStorageService.removeItem("geolocation");
            });
      }
   }, [surveyAlldata.toggleLocation]);

   const handleOpenDraftsModal = () => {
      setIsModalOpen(true);
   };

   const discardDraft = (draftId) => {
      deleteDraft(id, draftId);

      const draftSurveyId = [id, draftId];
      LocalStorageService.removeItem(`geolocation==${draftSurveyId}`);
      LocalStorageService.removeItem(`startTime==${draftSurveyId}`);
   };

   const onSaveAsDraft = async () => {
      const inputs = getInputsState();
      const inputValues = Object.values(inputs).filter(
         (input) =>
            ![InputType.NOTE, InputType.CALCULATE_QUESTION, InputType.HIDDEN].includes(
               input.questionType
            )
      );

      setHasErrors(false);
      if (inputValues?.length === 0) {
         message.open({
            type: "error",
            content:
               "Error: Please provide at least one piece of information before saving a draft."
         });

         return;
      }

      const renderedElements = document.querySelectorAll("[data-id]");
      const renderedElementIds = [...renderedElements].map((item) => item.attributes[0].value);

      const draftId = selectedDraft
         ? selectedDraft
         : dayjs()
              .valueOf()
              .toString(36);
      await saveDraft({
         surveyId: id,
         draftId: draftId,
         draftTitle: draftTitle,
         draftDescription: draftDesc,
         renderedElementIds: renderedElementIds,
         formData: inputs
      });

      const draftSurveyId = [id, draftId];
      if (surveyAlldata.toggleLocation) {
         getGeolocation()
            .then((geolocation) => {
               LocalStorageService.setItem(`geolocation==${draftSurveyId}`, geolocation);
            })
            .catch((error) => {
               console.error("Error setting geolocation for draft: " + draftTitle, error);
               LocalStorageService.removeItem("geolocation");
            });
      }

      const surveyStartTime = LocalStorageService.getItem(`startTime`);
      const draftSurveyStartTime = LocalStorageService.getItem(`startTime==${draftSurveyId}`);
      if (!draftSurveyStartTime) {
         LocalStorageService.setItem(`startTime==${draftSurveyId}`, surveyStartTime);
      }

      message.open({
         type: "success",
         content: "Your answers have been saved in your local device"
      });
      LocalStorageService.setItem("startTime", dayjs());

      draftFormik.resetForm();
      setSelectedDraft(null);
      resetInputs();

      setIsSaveAsDraft(false);
   };

   const countDown = () => {
      let secondsToGo = 10;

      const handleDone = () => {
         clearInterval(timer);
         instance.destroy();
         window.location.reload();
      };

      const instance = modalApi.success({
         width: 600,
         onOk: handleDone,
         title: "Survey queued for submission.",
         content: `Queued surveys, except those marked as draft, are uploaded automatically, 
         in the background when Internet connection is available. 
         Surveys are kept in queue for 30 days from the time they were submitted.`,
         footer: (_, { OkBtn }) => (
            <>
               <Button type="text">{secondsToGo}</Button>
               <OkBtn />
            </>
         )
      });

      const timer = setInterval(() => {
         secondsToGo -= 1;
         instance.update({
            footer: (_, { OkBtn }) => (
               <>
                  <Button type="text">{secondsToGo}</Button>
                  <OkBtn />
               </>
            )
         });
      }, 1000);

      setTimeout(handleDone, secondsToGo * 1000);
   };

   const checkIfUUIDExists = (uuid) => {
      const submittedUUIDs = LocalStorageService.getItem("submittedUUIDs") || [];
      return submittedUUIDs.includes(uuid);
   };

   // Function to save UUID to localStorage
   const saveUUIDToLocalStorage = (uuid) => {
      const submittedUUIDs = LocalStorageService.getItem("submittedUUIDs") || [];
      LocalStorageService.setItem("submittedUUIDs", [...submittedUUIDs, uuid]);
   };

   const onSubmit = async (
      selectedDraft,
      inputs,
      isDraft,
      draftRenderedElementIds,
      currentTaskId
   ) => {
      isSubmitting.current = true;
      // Check if UUID exists in localStorage
      if (checkIfUUIDExists(uuid)) {
         message.open({
            type: "error",
            content: "Survey has already been submitted. You can't submit more than once."
         });
         setLoad(false);
         return;
      }
      setHasErrors(false);
      const renderedElements = document.querySelectorAll("[data-id]");
      const renderedElementIds = [...renderedElements]
         .map((item) => item.attributes[0].value)
         .filter((id) => id !== "goog-gt-tt"); // Remove google translate tooltip id
      const renderedConstraintMessages = document.querySelectorAll(".preview-question-constraint");
      const geolocation = LocalStorageService.getItem(
         selectedDraft ? `geolocation==${[id, selectedDraft]}` : "geolocation"
      );
      const submissionData = {
         "submission.dataCollectionType": 0,
         "submission.SubmissionStartTime": startTimeEnd,
         "submission.isAnonymous": !AuthService.getUser()?.id,
         userId: AuthService.getUser()?.id || "",
         surveyId: id,
         ...(taskId ? { TaskId: currentTaskId ? currentTaskId : taskId } : {}),
         ...(receiverId ? { MessageReceiverId: receiverId } : {}),
         ...(surveyAlldata.toggleLocation && geolocation
            ? {
                 "submission.geolocation.latitude": geolocation.latitude,
                 "submission.geolocation.longitude": geolocation.longitude
              }
            : {})
      };

      const formData = new FormData();
      for (const [key, value] of Object.entries(submissionData)) {
         formData.append(key, value);
      }

      const inputValues = Object.values(inputs);
      const relevantErrors = useSurveyInputStore
         .getState()
         .getErrors(isDraft ? draftRenderedElementIds : renderedElementIds, inputs);

      if (relevantErrors.length > 0 || inputValues.length === 0) {
         if (!isDraft) {
            setHasErrors(true);

            const firstErrorElement = Array.from(renderedElements).find((el) =>
               relevantErrors.includes(el.attributes[0].value)
            );
            firstErrorElement?.scrollIntoView({ behavior: "smooth", block: "center" });
         }
         setLoad(false);
         message.open({
            type: "error",
            content:
               inputValues.length === 0
                  ? "Error: Please provide at least one piece of information before submitting."
                  : "Error: Please make sure you have filled all the required fields."
         });
         return;
      }

      if (renderedConstraintMessages && renderedConstraintMessages.length > 0) {
         setTimeout(function() {
            setLoad(false);
         }, 500);
         renderedConstraintMessages[0]?.scrollIntoView({ behavior: "smooth", block: "center" });
         return message.open({
            type: "error",
            content:
               "Error: Please check the constraints of the questions and provide the correct answers."
         });
      }

      inputValues
         .filter((input) => input.questionType !== InputType.SECTION)
         .forEach((input, index) => {
            const parsedQuestionElements = input.questionId.split("/");
            let id = input.questionId;
            let repeatGroup;
            const isRepeat = /\/\d+$/.test(input.questionId);
            if (isRepeat) {
               id = parsedQuestionElements.at(-2);
               repeatGroup = parsedQuestionElements.at(-1);
            }

            formData.append(`answers[${index}].surveyId`, input.surveyId);
            formData.append(`answers[${index}].userId`, input.userId);
            formData.append(`answers[${index}].questionId`, id);
            formData.append(`answers[${index}].answerDetail.questionType`, input.questionType);
            if (isRepeat) {
               formData.append(`answers[${index}].answerDetail.repeatGroup`, repeatGroup);
            }
            answerPayload(input.questionType, formData, index, input);
         });

      inputValues
         .filter((input) => input.questionType === InputType.SECTION)
         .forEach((input, index) => {
            const parsedQuestionElements = input.questionId.split("/");
            let id = input.questionId;
            const isRepeat = /\/\d+$/.test(input.questionId);
            if (isRepeat) {
               id = parsedQuestionElements.at(-2);
            }

            formData.append(`sectionRepeatGroups[${index}].sectionId`, id);
            formData.append(`sectionRepeatGroups[${index}].repeatGroup`, input.answerDetail.number);
         });

      for (const [index, el] of renderedElementIds.entries()) {
         const parsedQuestionElements = el.split("/");
         let id;
         const isRepeat = /\/\d+$/.test(el);
         if (isRepeat) {
            id = parsedQuestionElements.at(-2);
         }
         formData.append(`questionIds[${index}]`, isRepeat ? id : el);
      }

      return https
         .post("/DataCollection/PublishSurvey", formData, {
            headers: {
               "Content-Type": "multipart/form-data"
            }
         })
         .then((response) => {
            if (response.data.isSuccess) {
               if (!Boolean(taskId) && !Boolean(receiverId)) {
                  message.open({
                     type: "success",
                     content: response.data.message
                  });
                  saveUUIDToLocalStorage(uuid);
                  setTimeout(() => window.location.reload(), 1500);
               }

               if (Boolean(taskId)) {
                  setSurveyStatusUpdate(true);
                  if (!currentTaskId) {
                     navigate("/mytasks");
                  }
               }

               if (Boolean(receiverId)) {
                  setSurveyStatusUpdate(true);
                  setTimeout(() => window.location.reload(), 1500);
               }

               if (selectedDraft) {
                  discardDraft(selectedDraft);
               }

               LocalStorageService.removeItem("startTime");
               LocalStorageService.removeItem("geolocation");
            } else {
               setHasErrors(true);
               setLoad(false);
            }

            return response;
         })
         .catch((err) => {
            if (isPublicSurveyRoute && !isOnline) {
               if (selectedDraft) {
                  discardDraft(selectedDraft);
               }

               LocalStorageService.removeItem(`startTime`);
               LocalStorageService.removeItem(`geolocation`);
               countDown();
            }
            if (isOnline) {
               isSubmitting.current = false;
               const res = err.response.data;
               notification.error({
                  message: (
                     <div>
                        <div>
                           {res.message ||
                              res.title ||
                              "Something went wrong! Please try again later."}
                        </div>
                        {Array.isArray(res?.errors) && (
                           <Fragment>
                              <br />
                              <ul>
                                 {res?.errors?.map((err) => (
                                    <li key={err}>{err}</li>
                                 ))}
                              </ul>
                           </Fragment>
                        )}
                     </div>
                  )
               });
            }
            setLoad(false);

            return err;
         });
   };

   const handleSubmittingAllDrafts = () => {
      setSubmittingAllDrafts((prevState) => !prevState);
   };

   const shouldAddArabicClass = !toLanguage
      ? surveyBaseLanguageName === Constants.ARABIC_LANGUAGE_ID
      : toLanguage === Constants.ARABIC_LANGUAGE_ID;

   return (
      <>
         {loading ? (
            <div className="loading-container">
               <Spin />
            </div>
         ) : (
            <>
               <div
                  ref={componentRef}
                  style={{
                     backgroundColor: "#EEF2FF"
                  }}
               >
                  <PreviewBanner
                     surveyTitle={surveyAlldata?.name}
                     surveyDescription={surveyAlldata?.description}
                     surveyEndDate={surveyAlldata?.endDate}
                  />

                  {checkIfUUIDExists(uuid) || (surveyStatusUpdated && receiverId) ? (
                     <SurveyConfirmation />
                  ) : Boolean(surveyAlldata.id) &&
                    (taskId || receiverId) &&
                    !surveyAlldata?.canSubmitSurvey ? (
                     <SurveyConfirmation isSurveySubmitted />
                  ) : (
                     <div className="survey-previewer">
                        {isEditingSurvey ? (
                           <Alert
                              prefixCls="survey-previewer--alert"
                              type="warning"
                              message="This is a preview of your survey draft."
                              description="Once you finish editing your survey, publish it and start collecting data"
                           />
                        ) : null}

                        <div
                           className={`${shouldAddArabicClass ? "arabic-survey" : ""}`}
                           style={{
                              border: "1px solid #9BACEB",
                              borderRadius: "8px",
                              overflow: "hidden"
                           }}
                        >
                           <PreviewLanguage ref={componentRef} surveyAlldata={surveyAlldata} />

                           {surveyData?.length ? (
                              <SurveyDataItems
                                 hasErrors={hasErrors}
                                 submissionId={submissionId}
                                 surveyData={surveyData}
                                 shouldAddArabicClass={shouldAddArabicClass}
                              />
                           ) : (
                              <Empty
                                 style={{
                                    padding: "50px 0"
                                 }}
                              />
                           )}
                        </div>

                        {!submissionId && !isEditingSurvey && (
                           <PreviewActions
                              seeDraftsProps={{
                                 onClick: handleOpenDraftsModal
                              }}
                              saveDraftProps={{
                                 onClick: () => {
                                    if (selectedDraft) {
                                       onSaveAsDraft();
                                       return;
                                    }
                                    setIsSaveAsDraft(true);
                                 }
                              }}
                              submitProps={{
                                 onClick: () => {
                                    const inputs = getInputsState();
                                    setLoad(true);
                                    onSubmit(selectedDraft, inputs);
                                 },
                                 loading: load,
                                 style: {
                                    display: !isOnline && (taskId || receiverId) ? "none" : "block"
                                 }
                              }}
                           />
                        )}
                     </div>
                  )}
               </div>

               {/* Drafts modal - START */}
               <PreviewModal
                  width={922}
                  isModalOpen={!hasSurveyExpired && isModalOpen && surveyAlldata?.canSubmitSurvey}
                  setIsModalOpen={setIsModalOpen}
                  title="Saved drafts"
                  titleStyle={{
                     textAlign: "center",
                     fontSize: "21px",
                     fontWeight: 600
                  }}
                  footer={null}
               >
                  <div className="show-drafts-modal">
                     <p className="show-drafts-modal--description">
                        Below is the list of all your saved drafts. They will be deleted after 30
                        days if not submitted.
                        {/* TODO: write better description. 30 days bit is incorrect */}
                     </p>
                     <div>
                        <DraftList
                           selectedDraft={selectedDraft}
                           onDiscardDraft={discardDraft}
                           onDraftSelect={async (draftId) => {
                              if (draftId === START_NEW_DRAFT.draftId) {
                                 setSelectedDraft(null);
                                 setHasErrors(false);
                                 resetInputs();
                                 draftFormik.resetForm();
                              } else {
                                 const draftData = await loadDraft(id, draftId);
                                 if (draftData) {
                                    draftFormik.setValues({
                                       draftTitle: draftData.draftTitle,
                                       draftDesc: draftData.draftDescription
                                    });
                                 }
                                 setSelectedDraft(draftId);
                                 setDraftSurvey(id, draftId);
                              }
                              setIsModalOpen(false);
                           }}
                           onDraftSubmit={async (draftId) => {
                              const draftData = await loadDraft(id, draftId);

                              await onSubmit(
                                 draftId,
                                 draftData.formData,
                                 true,
                                 draftData.renderedElementIds
                              );
                           }}
                           submittingAllDrafts={submittingAllDrafts}
                           handleSubmittingAllDrafts={handleSubmittingAllDrafts}
                        />
                     </div>

                     <div className="show-drafts-modal--form---actions">
                        <Button
                           prefixCls="show-drafts-modal--form---actions_cancel"
                           type="default"
                           onClick={() => {
                              setIsModalOpen(false);
                           }}
                        >
                           Cancel
                        </Button>
                        {(!isOnline && taskId) || receiverId ? null : (
                           <Button
                              prefixCls="show-drafts-modal--form---actions_save"
                              htmlType="submit"
                              type="primary"
                              loading={submittingAllDrafts}
                              disabled={draftCount <= 0}
                              onClick={async () => {
                                 try {
                                    setSubmittingAllDrafts(true);
                                    const surveyDrafts = await getAllDraftsForSurvey(id);
                                    const inputsMap = surveyDrafts.map(
                                       ({ draftId, formData, renderedElementIds }) => [
                                          draftId,
                                          formData,
                                          true,
                                          renderedElementIds
                                       ]
                                    );

                                    if (Boolean(taskId)) {
                                       const limit = Math.min(
                                          inputsMap.length,
                                          assignedTaskIdsForSurvey.length
                                       );

                                       await Promise.all(
                                          inputsMap.slice(0, limit).map((args, index) => {
                                             const currentTaskId = assignedTaskIdsForSurvey[index];
                                             const modifiedArgs = [...args, currentTaskId];
                                             return onSubmit(...modifiedArgs);
                                          })
                                       );
                                       navigate("/mytasks");
                                    } else {
                                       await Promise.all(
                                          inputsMap.map(async (args) => await onSubmit(...args))
                                       );
                                    }

                                    setSubmittingAllDrafts(false);
                                 } catch (err) {
                                    console.error(err);
                                    setSubmittingAllDrafts(false);
                                 }
                              }}
                           >
                              <SendOutlined
                                 style={{
                                    translate: "0 -5px"
                                 }}
                                 rotate={-45}
                              />
                              <span>Submit all drafts</span>
                           </Button>
                        )}
                     </div>
                  </div>
               </PreviewModal>
               {/* Drafts modal - END */}

               {/* Save draft modal - START */}
               <PreviewModal
                  isModalOpen={isSaveAsDraft}
                  setIsModalOpen={setIsSaveAsDraft}
                  title="Save as a draft"
                  titleStyle={{
                     textAlign: "center"
                  }}
                  footer={null}
               >
                  <div className="save-draft-modal">
                     <p className="save-draft-modal--description">
                        To keep a draft of this survey, assign a name to it to review it later.{" "}
                        <br />
                        If they are not submitted within <b>30 days</b> they will be permanently
                        deleted.
                        {/* TODO: write better description. 30 days bit is incorrect */}
                     </p>
                     <form
                        onSubmit={(e) => {
                           e.preventDefault();
                           onSaveAsDraft();
                        }}
                        className="save-draft-modal--form"
                     >
                        <div>
                           <div className="save-draft-modal--form---control">
                              <label htmlFor="draftTitle">
                                 <span
                                    style={{
                                       color: "#FF4D4F"
                                    }}
                                 >
                                    *
                                 </span>{" "}
                                 Draft name
                              </label>
                              <Input
                                 id="draftTitle"
                                 required
                                 type="text"
                                 placeholder="Type something here..."
                                 value={draftFormik.values.draftTitle}
                                 onChange={draftFormik.handleChange}
                              />
                           </div>
                           <div className="save-draft-modal--form---control">
                              <label
                                 htmlFor="draftDesc"
                                 style={{
                                    marginTop: "32px"
                                 }}
                              >
                                 Note{" "}
                                 <span
                                    style={{
                                       color: "#979797",
                                       fontSize: "12px",
                                       fontWeight: 400
                                    }}
                                 >
                                    (optional)
                                 </span>
                              </label>
                              <Input.TextArea
                                 id="draftDesc"
                                 showCount
                                 maxLength={100}
                                 autoSize={{ minRows: 2 }}
                                 placeholder="Autosize height based on content lines"
                                 value={draftFormik.values.draftDesc}
                                 onChange={draftFormik.handleChange}
                              />
                           </div>
                        </div>
                        <div className="save-draft-modal--form---actions">
                           <Button
                              prefixCls="save-draft-modal--form---actions_cancel"
                              type="default"
                              onClick={() => {
                                 setIsSaveAsDraft(false);
                                 draftFormik.resetForm();
                              }}
                           >
                              Cancel
                           </Button>
                           <Button
                              prefixCls="save-draft-modal--form---actions_save"
                              htmlType="submit"
                              type="primary"
                           >
                              Save and close
                           </Button>
                        </div>
                     </form>
                  </div>
               </PreviewModal>
               {/* Save draft modal - END */}

               {/* Survey expired modal - START */}
               <PreviewModal
                  isModalOpen={hasSurveyExpired}
                  setIsModalOpen={() => {
                     if (!isPublicSurveyRoute()) {
                        setHasSurveyExpired(false);
                     }
                  }}
                  title="Survey expired"
                  titleStyle={{
                     textAlign: "center"
                  }}
                  footer={null}
               >
                  {/* we are borrowing the styles from drafts modal */}
                  <div>
                     <h5 className="show-drafts-modal--description">
                        Deadline: {dayjs(surveyAlldata?.endDate).format("MMMM Do, YYYY")}
                     </h5>
                     <p className="show-drafts-modal--description">
                        The survey has expired and can no longer be submitted. Please contact the
                        survey creator for more information.
                     </p>
                  </div>
               </PreviewModal>
               {/* Survey expired modal - END */}

               <>{modalContextHolder}</>
            </>
         )}
      </>
   );
}

export default withErrorBoundary(Preview);
