import React, { useEffect } from "react";
import { DatePicker, TimePicker } from "antd";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import PreviewQuestionLabel from "../../../common/PreviewQuestionLabel/PreviewQuestionLabel";
import { useSurveyInputStore } from "../../../../surveyStore";
import PreviewQuestionConstraints from "../../../common/PreviewQuestionConstraints/PreviewQuestionConstraints";
import useEvaluateConstraint from "../../question-preview/hooks/useEvaluateConstraint";

import "./DateTime.scss";

dayjs.extend(customParseFormat);

const TIME_FORMAT = "hh:mm";
const DATE_FORMAT = "YYYY-MM-DD";

export const DateTime = React.memo(
   ({ props, hasErrors, surveyId, userId, submissionId, label, hint, defaultValue }) => {
      const { id, questionType, isRequired, answers } = props;

      const inputState = useSurveyInputStore((state) => state.inputs?.[id]);
      const setInputs = useSurveyInputStore((state) => state.setInputs);

      const [date, time] = inputState?.answerDetail?.text?.split(" ") ?? ["", ""];

      const hasSubmissionId = Boolean(submissionId);

      const submissionAnswer = submissionId
         ? answers?.find((answer) =>
              props.repeatInstanceIndex
                 ? answer?.answerDetail.repeatGroup === props.repeatInstanceIndex
                 : answer
           )?.answerDetail?.text
         : null;

      const passConstraint = useEvaluateConstraint(
         !hasSubmissionId ? props.constraint : null,
         inputState?.skipLogicAnswer
      );

      const showErrors =
         hasErrors && isRequired && (!inputState || !inputState?.answerDetail?.text);

      const hasSubmissionValue =
         hasSubmissionId && submissionAnswer && submissionAnswer.includes(" ");

      const [dateValueFromSubmission, timeValueFromSubmission] = hasSubmissionValue
         ? submissionAnswer.split(" ")
         : [null, null];

      const dateValue = hasSubmissionValue
         ? dayjs(dateValueFromSubmission, DATE_FORMAT)
         : inputState?.answerDetail?.text?.includes(" ") && date !== ""
         ? dayjs(date, DATE_FORMAT)
         : null;

      const timeValue = hasSubmissionValue
         ? dayjs(timeValueFromSubmission, TIME_FORMAT)
         : inputState?.answerDetail?.text?.includes(" ") && time !== ""
         ? dayjs(time, TIME_FORMAT)
         : null;

      const onChangeDate = (date, dateString) => {
         if (hasSubmissionId) {
            return;
         }
         setInputs({
            target: {
               name: id,
               value: {
                  surveyId: surveyId,
                  userId: userId,
                  questionId: id,
                  questionType: questionType,
                  skipLogicAnswer: date.startOf("day"),
                  answerDetail: {
                     text: `${dateString} ${time === "" ? dayjs().format(TIME_FORMAT) : time}`
                  }
               }
            }
         });
      };

      const onChangeTime = (time, timeString) => {
         if (hasSubmissionId) {
            return;
         }
         setInputs({
            target: {
               name: id,
               value: {
                  surveyId: surveyId,
                  userId: userId,
                  questionId: id,
                  questionType: questionType,
                  skipLogicAnswer: time,
                  answerDetail: {
                     text: `${date === "" ? dayjs().format(DATE_FORMAT) : date} ${timeString}` // Update with current date
                  }
               }
            }
         });
      };

      useEffect(() => {
         if (defaultValue && !hasSubmissionId) {
            const defaultDate = defaultValue?.$isDayjsObject ? defaultValue : dayjs(defaultValue);

            setInputs({
               target: {
                  name: id,
                  value: {
                     surveyId: surveyId,
                     userId: userId,
                     questionId: id,
                     questionType: questionType,
                     skipLogicAnswer: defaultDate,
                     answerDetail: {
                        text: `${defaultDate.format(DATE_FORMAT)} ${defaultDate.format(
                           TIME_FORMAT
                        )}`
                     }
                  }
               }
            });
         }
         // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [defaultValue, props.default]);

      return (
         <div className="date_time-submission-type-wrapper">
            <PreviewQuestionLabel label={label} isRequired={isRequired} hint={hint} />

            <DatePicker
               size="large"
               placeholder={DATE_FORMAT}
               inputReadOnly={hasSubmissionId}
               {...(hasSubmissionId && { open: false })}
               status={showErrors || !passConstraint ? "error" : ""}
               onChange={onChangeDate}
               value={dateValue}
               prefixCls="date_time-submission-type-wrapper--date-picker"
            />
            <TimePicker
               size="large"
               format={TIME_FORMAT}
               placeholder={TIME_FORMAT}
               inputReadOnly={hasSubmissionId}
               {...(hasSubmissionId && { open: false })}
               status={showErrors || !passConstraint ? "error" : ""}
               onChange={onChangeTime}
               value={timeValue}
               prefixCls="date_time-submission-type-wrapper--time-picker"

               // use12Hours={true}
               // panelRender={() => <CustomPanel onChange={onTimePickerChange} />}
            />

            <span className="date_time-submission-type-wrapper--error">
               {showErrors ? "This field is required" : " "}
               {!passConstraint ? (
                  <PreviewQuestionConstraints constraintMessages={props.constraintMessages} />
               ) : null}
            </span>
         </div>
      );
   }
);
