import React from "react";
import { marked } from "marked";

import "./index.scss";

const Marked = ({ children, className }) => {
   const content = marked(children ?? "");

   return <div className={className} dangerouslySetInnerHTML={{ __html: content }}></div>;
};

export default Marked;
