import React, { useCallback, useEffect, useMemo } from "react";
import { Checkbox } from "antd";
import PreviewQuestionLabel from "../../../common/PreviewQuestionLabel/PreviewQuestionLabel";
import PreviewQuestionConstraints from "../../../common/PreviewQuestionConstraints/PreviewQuestionConstraints";
import { useSurveyInputStore } from "../../../../surveyStore";
import { Constants } from "../../../../../../../utils/constants";
import useEvaluateConstraint from "../../question-preview/hooks/useEvaluateConstraint";

import "./Acknowledge.scss";

export const Acknowledge = React.memo(
   ({ props, hasErrors, surveyId, userId, language, submissionId, label, hint, defaultValue }) => {
      const { id, questionType, selectQuestionChoices, isRequired, answers, name } = props;

      const inputState = useSurveyInputStore((state) => state.inputs?.[id]);
      const setInputs = useSurveyInputStore((state) => state.setInputs);

      const hasSubmissionId = Boolean(submissionId);

      const submissionAnswer = submissionId
         ? answers?.find((answer) =>
              props.repeatInstanceIndex
                 ? answer?.answerDetail.repeatGroup === props.repeatInstanceIndex
                 : answer
           )?.answerDetail?.questionChoice
         : null;

      const value = hasSubmissionId
         ? submissionAnswer
         : inputState?.answerDetail?.questionChoice ?? false;

      const passConstraint = useEvaluateConstraint(
         !hasSubmissionId ? props.constraint : null,
         value
      );

      const showErrors =
         hasErrors && isRequired && (!inputState || !inputState?.answerDetail?.questionChoice);

      const checkboxLabel = useMemo(() => {
         const label = selectQuestionChoices?.[0]?.choiceLabels?.find(
            (choice) => choice.languageId === (language || Constants.ENGLISH_LANGUAGE_ID)
         )?.text;

         const defaultLabel = selectQuestionChoices?.[0]?.choiceLabels?.[0]?.text || "( No label )";

         return label || defaultLabel;
      }, [language, selectQuestionChoices]);

      useEffect(() => {
         setInputs({
            target: {
               name: id,
               value: {
                  surveyId: surveyId,
                  userId: userId,
                  questionId: id,
                  questionType: questionType,
                  name: props.name,
                  skipLogicAnswer: defaultValue,
                  answerDetail: {
                     questionChoice: defaultValue
                  }
               }
            }
         });
         // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [defaultValue]);

      const onCheckboxChange = useCallback(
         (e) => {
            if (hasSubmissionId) {
               return;
            }
            setInputs({
               target: {
                  name: id,
                  value: {
                     surveyId: surveyId,
                     userId: userId,
                     questionId: id,
                     questionType: questionType,
                     name: name,
                     skipLogicAnswer: e.target.checked,
                     answerDetail: {
                        questionChoice: e.target.checked
                     }
                  }
               }
            });
         },
         [hasSubmissionId, id, name, questionType, setInputs, surveyId, userId]
      );

      return (
         <div className="acknowledge-submission-type">
            <PreviewQuestionLabel label={label} isRequired={isRequired} hint={hint} />

            <div
               className={`select_multiple-submission-type--options${
                  showErrors || (passConstraint !== null && !passConstraint) ? "_error" : ""
               }`}
            >
               <Checkbox
                  onChange={onCheckboxChange}
                  checked={value}
                  prefixCls="acknowledge-submission-type--options---checkbox"
               >
                  {checkboxLabel}
               </Checkbox>
            </div>

            <span className="acknowledge-submission-type--error">
               {showErrors ? "This field is required" : " "}
               {passConstraint !== null && !passConstraint ? (
                  <PreviewQuestionConstraints constraintMessages={props.constraintMessages} />
               ) : null}
            </span>
         </div>
      );
   }
);
