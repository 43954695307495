import React, { useEffect } from "react";
import { TimePicker } from "antd";
import customParseFormat from "dayjs/plugin/customParseFormat";
import PreviewQuestionLabel from "../../../common/PreviewQuestionLabel/PreviewQuestionLabel";
import dayjs from "dayjs";
import { useSurveyInputStore } from "../../../../surveyStore";
import PreviewQuestionConstraints from "../../../common/PreviewQuestionConstraints/PreviewQuestionConstraints";
import useEvaluateConstraint from "../../question-preview/hooks/useEvaluateConstraint";

import "./Time.scss";

dayjs.extend(customParseFormat);

export const Time = React.memo(
   ({ props, hasErrors, surveyId, userId, submissionId, label, hint, defaultValue }) => {
      const { id, questionType, isRequired, answers } = props;

      const inputState = useSurveyInputStore((state) => state.inputs?.[id]);
      const setInputs = useSurveyInputStore((state) => state.setInputs);

      const hasSubmissionId = Boolean(submissionId);

      const submissionAnswer = submissionId
         ? answers?.find((answer) =>
              props.repeatInstanceIndex
                 ? answer?.answerDetail.repeatGroup === props.repeatInstanceIndex
                 : answer
           )?.answerDetail?.text
         : null;

      const value =
         hasSubmissionId && submissionAnswer
            ? dayjs(submissionAnswer, "HH:mm")
            : inputState
            ? dayjs(inputState?.answerDetail?.text, "HH:mm")
            : "";

      const passConstraint = useEvaluateConstraint(
         !hasSubmissionId ? props.constraint : null,
         inputState?.skipLogicAnswer
      );

      const showErrors =
         hasErrors && isRequired && (!inputState || !inputState?.answerDetail?.text);

      const onTimePickerChange = (date, timeString) => {
         if (hasSubmissionId) {
            return;
         }

         if (date) {
            setInputs({
               target: {
                  name: id,
                  value: {
                     surveyId: surveyId,
                     userId: userId,
                     questionId: id,
                     questionType: questionType,
                     skipLogicAnswer: date,
                     answerDetail: {
                        text: timeString
                     }
                  }
               }
            });
         } else {
            setInputs({ target: { name: id, value: "" } });
         }
      };

      useEffect(() => {
         if (defaultValue) {
            const defaultDate = defaultValue?.$isDayjsObject ? defaultValue : dayjs(props.default);

            onTimePickerChange(defaultDate, defaultDate.format("HH:mm"));
         }
         // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [defaultValue]);

      return (
         <div className="time-submission-type-wrapper">
            <PreviewQuestionLabel label={label} isRequired={isRequired} hint={hint} />

            <TimePicker
               size="large"
               format="hh:mm"
               placeholder="hh:mm"
               value={value}
               onChange={onTimePickerChange}
               inputReadOnly={hasSubmissionId}
               {...(hasSubmissionId && { open: false })}
               status={showErrors || (passConstraint !== null && !passConstraint) ? "error" : ""}
               prefixCls="time-submission-type-wrapper--time-picker"
               // use12Hours={true}

               // panelRender={() => <CustomPanel onChange={onTimePickerChange} />}
            />

            <span className="time-submission-type-wrapper--error">
               {showErrors ? "This field is required" : " "}
               {passConstraint !== null && !passConstraint ? (
                  <PreviewQuestionConstraints constraintMessages={props.constraintMessages} />
               ) : null}
            </span>
         </div>
      );
   }
);

// function CustomPanel({ onChange }) {
//    const [hour, setHour] = useState(1);
//    const [minute, setMinute] = useState(0);
//    const [meridiem, setMeridiem] = useState(0);

//    const increase = (setFn, isMer) => () => {
//       setFn((prev) => (prev < (isMer ? 1 : 12) ? prev + 1 : prev));
//    };

//    const decrease = (setFn) => () => {
//       setFn((prev) => (prev > 0 ? prev - 1 : prev));
//    };

//    const toggleMeridiem = () => {
//       setMeridiem((prev) => (prev === 0 ? 1 : 0));
//    };

//    useEffect(() => {
//       const date = dayjs()
//          .set("hour", meridiem === 0 ? hour : hour + 12)
//          .set("minute", minute);

//       const timeString = date.format("hh:mm A");
//       onChange(date, timeString);
//       // eslint-disable-next-line react-hooks/exhaustive-deps
//    }, [hour, minute, meridiem]);

//    return (
//       <div
//          style={{
//             padding: "12px 16px"
//          }}
//       >
//          <Flex align="center" gap={16} className="border">
//             <Flex vertical align="center" gap={10}>
//                <Button
//                   icon={<UpOutlined />}
//                   onClick={() => {
//                      setHour((prev) => (prev < 12 ? prev + 1 : 1));
//                   }}
//                />
//                <InputNumber
//                   type="number"
//                   size="large"
//                   style={{ textAlign: "center", width: "53px", height: "43px" }}
//                   min={0}
//                   max={12}
//                   value={String(hour).padStart(2, "0")}
//                   onChange={({ target }) => setHour(target.value)}
//                />
//                <Button
//                   icon={<DownOutlined />}
//                   onClick={() => {
//                      setHour((prev) => (prev > 1 ? prev - 1 : 12));
//                   }}
//                />
//             </Flex>

//             <Flex vertical align="center" gap={10}>
//                <Button icon={<UpOutlined />} onClick={increase(setMinute)} />
//                <InputNumber
//                   type="number"
//                   size="large"
//                   style={{ textAlign: "center", width: "53px", height: "43px" }}
//                   min={0}
//                   max={59}
//                   value={String(minute).padStart(2, "0")}
//                   onChange={({ target }) => setMinute(target.value)}
//                />
//                <Button icon={<DownOutlined />} onClick={decrease(setMinute)} />
//             </Flex>

//             <Flex vertical align="center" gap={10}>
//                <Button icon={<UpOutlined />} onClick={toggleMeridiem} />
//                <Input
//                   size="large"
//                   style={{ textAlign: "center", width: "53px", height: "43px" }}
//                   readOnly
//                   min={0}
//                   max={1}
//                   inputMode="text"
//                   value={meridiem === 0 ? "AM" : "PM"}
//                />
//                <Button icon={<DownOutlined />} onClick={toggleMeridiem} />
//             </Flex>
//          </Flex>
//       </div>
//    );
// }
