import React, { useState, useEffect } from "react";
import { Modal, Form, Input, Select, Button, Row, Col, notification } from "antd";
import {
   getLanguageIdByName,
   getLocationIdByName,
   getRoleIdByName,
   getWorkspaceIdByName
} from "../helpers";
import { httpService } from "../../../base/services/httpService.service";
import { LocalStorageService } from "../../../base/services/local-storage.service";

import styles from "./UserManagement.module.scss";
import AuthService from "../../../base/services/authentication.service";
import { UserRolesEnum } from "../../../utils/enums";

const { Option } = Select;

const EditMemberModal = ({
   visible,
   onCancel,
   editMemberData,
   rolesList,
   languages,
   countries,
   workspaces,
   adminUserManagementPage = false,
   fetchTableData = () => {}
}) => {
   const [form] = Form.useForm();
   const [loading, setLoading] = useState(false);
   const userId = LocalStorageService?.getItem("user")?.id || "";

   useEffect(() => {
      if (editMemberData) {
         const rolesNames = editMemberData.roles?.map((role) =>
            role?.name?.replace(/([a-z])([A-Z])/g, "$1 $2")
         );
         const workspacesNames = editMemberData.workspaces?.map((workspace) => workspace?.name);
         const languagesNames = editMemberData.selectedLanguages?.map((language) => language?.name);

         form.setFieldsValue({
            fullName: editMemberData?.fullName,
            email: editMemberData?.email,
            gender: editMemberData?.gender,
            role: rolesNames, // Array of role names
            location: adminUserManagementPage
               ? editMemberData?.country?.name
               : editMemberData?.location,
            workspace: workspacesNames, // Array of workspace names
            language: languagesNames // Array of language names
         });
      }
   }, [adminUserManagementPage, editMemberData, form]);

   const handleUpdate = (api, values, editMemberData) => {
      setLoading(true);
      httpService.post(
         `/Members/${"edit"}`,
         {
            FullName: values.fullName,
            Email: values.email,
            gender: values.gender,
            roleId: getRoleIdByName(rolesList, values),
            workspaceIds: !adminUserManagementPage
               ? [LocalStorageService.getItem("currentWorkspaceId")]
               : getWorkspaceIdByName(workspaces, values),
            languages: getLanguageIdByName(languages, values),
            locationId: getLocationIdByName(countries, values),
            id: editMemberData?.id,
            ...(!adminUserManagementPage
               ? { projectId: LocalStorageService.getItem("currentProjectID") }
               : {})
         },
         (res) => {
            if (res?.isSuccess) {
               fetchTableData();
               notification.open({
                  type: "success",
                  message: "Member has been updated successfully."
               });
               setLoading(false);
               onCancel("editMemberModal");
            }
         },
         (error) => {
            setLoading(false);
            if (error.response && error.response.data && error.response.data.errors) {
               const errorMessage = Object.values(error.response.data.errors)
                  .flat()
                  .join(" ");
               notification.error({
                  type: "error",
                  message: errorMessage
               });
            } else {
               notification.error({
                  type: "error",
                  message: `Something went wrong`
               });
            }
         }
      );
   };

   return (
      <Modal
         open={visible}
         onCancel={() => onCancel("editMemberModal")}
         width={800}
         closable={false}
      >
         <Row gutter={16} justify="center">
            <Col span={23}>
               {" "}
               <h1
                  className="modal-heading"
                  style={{
                     color: "#4158CF",
                     textAlign: "center",
                     marginLeft: "50px",
                     fontSize: "22px"
                  }}
               >
                  Edit Member
               </h1>
            </Col>
            <Col span={1} style={{ textAlign: "right" }}>
               {" "}
               <Button type="text" onClick={() => onCancel("editMemberModal")}>
                  <svg
                     xmlns="http://www.w3.org/2000/svg"
                     width="19"
                     height="18"
                     viewBox="0 0 19 18"
                     fill="none"
                  >
                     <path
                        d="M17.1855 1.16675L1.18555 16.9644"
                        stroke="#4158CF"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                     />
                     <path
                        d="M1.18555 1.16675L17.1855 16.9644"
                        stroke="#4158CF"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                     />
                  </svg>
               </Button>
            </Col>
         </Row>
         <Form form={form} layout="vertical">
            <Row gutter={24}>
               <Col span={12}>
                  <Form.Item
                     label="Full Name"
                     name="fullName"
                     rules={[{ required: true, message: "Please enter full name" }]}
                  >
                     <Input />
                  </Form.Item>
               </Col>
               <Col span={12}>
                  <Form.Item
                     label="Email"
                     name="email"
                     rules={[{ required: true, message: "Please enter email" }]}
                  >
                     <Input disabled />
                  </Form.Item>
               </Col>
               <Col span={12}>
                  <Form.Item label="Gender" name="gender">
                     <Select>
                        <Option value="Male">Male</Option>
                        <Option value="Female">Female</Option>
                        <Option value="Other">Other</Option>
                     </Select>
                  </Form.Item>
               </Col>
               <Col span={12}>
                  {" "}
                  <Form.Item
                     label="Language*"
                     name="language"
                     rules={[
                        {
                           required: true,
                           message:
                              "Kindly specify your language preference for a tailored experience."
                        }
                     ]}
                  >
                     <Select
                        mode="multiple"
                        showSearch
                        allowClear
                        placeholder="--Select an option--"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                           option.children.toLowerCase().startsWith(input.toLowerCase())
                        }
                        style={{ width: "100%" }}
                     >
                        {languages.map((option, i) => (
                           <Option value={option.label} key={i}>
                              {option.label}
                           </Option>
                        ))}
                     </Select>
                  </Form.Item>
               </Col>
               <Col span={12}>
                  <Form.Item
                     label="Location*"
                     name="location"
                     rules={[
                        {
                           required: true,
                           message:
                              "For accurate information, please provide your current location."
                        }
                     ]}
                  >
                     <Select
                        showSearch
                        placeholder="--Select a Location--"
                        allowClear
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                           option.children.toLowerCase().startsWith(input.toLowerCase())
                        }
                        style={{ width: "100%" }}
                     >
                        {countries.map((option, i) => (
                           <Option value={option.label} key={i}>
                              {option.label}
                           </Option>
                        ))}
                     </Select>
                  </Form.Item>
               </Col>
               {adminUserManagementPage && (
                  <Col span={12}>
                     <Form.Item
                        label="Select Workspaces*"
                        name="workspace"
                        rules={[
                           {
                              required: true,
                              message: "For organizational purposes, please indicate your workspace"
                           }
                        ]}
                     >
                        <Select
                           placeholder="--Select Workspaces--"
                           mode="multiple"
                           allowClear
                           showSearch
                           // value={initialValues.workspace}
                        >
                           {workspaces.map((option, i) => {
                              return (
                                 <Option value={option.name} key={i}>
                                    {option.name}{" "}
                                 </Option>
                              );
                           })}
                        </Select>
                     </Form.Item>
                  </Col>
               )}
               <Col span={12}>
                  <Form.Item
                     label="Select Role*"
                     name="role"
                     rules={[
                        {
                           required: true,
                           message: "Specify your role or position within the organization."
                        }
                     ]}
                  >
                     <Select
                        placeholder="--Select a Role--"
                        showSearch
                        disabled={editMemberData.id === userId}
                     >
                        {rolesList
                           ?.filter((item) =>
                              adminUserManagementPage
                                 ? ["Senior Manager", "Project Manager", "Guest"].includes(
                                      item.name
                                   )
                                 : AuthService.isAuthorized([UserRolesEnum.SeniorProjectManager])
                                 ? ["Data Collector", "Junior Manager", "Project Manager"].includes(
                                      item.name
                                   )
                                 : ["Data Collector", "Junior Manager"].includes(item.name)
                           )
                           .map((option, i) => (
                              <Option value={option.name} key={i}>
                                 {option.name}
                              </Option>
                           ))}
                     </Select>
                  </Form.Item>
               </Col>
               <Col span={24}>
                  <div className={styles.buttonsDiv}>
                     <Button
                        onClick={() => onCancel("editMemberModal")}
                        className={styles.buttonCancel}
                     >
                        Cancel
                     </Button>

                     <Form.Item>
                        <Button
                           htmlType="submit"
                           className={styles.buttonsCreateProfile}
                           disabled={loading}
                           loading={loading}
                           onClick={() =>
                              handleUpdate("edit", form.getFieldsValue(), editMemberData)
                           }
                        >
                           {loading ? <>Processing</> : "Edit Member"}
                        </Button>
                     </Form.Item>
                  </div>
               </Col>
            </Row>
         </Form>
      </Modal>
   );
};

export default EditMemberModal;
