import React, { Fragment, useState } from "react";
import { Button, Flex, List, Popconfirm, Popover, Tooltip } from "antd";
import {
   DeleteOutlined,
   EditOutlined,
   FileOutlined,
   FormOutlined,
   InfoCircleOutlined,
   SendOutlined
} from "@ant-design/icons";
import dayjs from "dayjs";
import { db_drafts } from "../../../../../../storage/db";
import { useLiveQuery } from "dexie-react-hooks";
import { useParams, useSearchParams } from "react-router-dom";
import useOnlineStore from "../../../../../../utils/useOnlineStore";

import "./DraftList.scss";

const defaultDraftDesc = `Draft means that the form is still in progress. 
Perhaps you've started filling the form out, but have not yet finished it. 
Or maybe different sections of the form need to be filled out by different people, 
and the form is somewhere in the middle of that workflow. 
Completed means that the form is done.`;

const getDraftTimestamp = (timestamp) => {
   return timestamp ? dayjs(timestamp).format("DD MMM YYYY") : "Start now!";
};
export const START_NEW_DRAFT = {
   draftId: "Start new draft",
   draftTitle: "Start new draft",
   draftDescription: defaultDraftDesc
};

const ActionButton = ({ icon, onClick, loading, disabled, hideSubmitDraftButton = false }) => {
   return (
      <Button
         prefixCls="draft-list-action-button"
         onClick={onClick}
         loading={loading}
         disabled={disabled}
         style={{
            display: hideSubmitDraftButton ? "none" : "block"
         }}
      >
         {!loading && (
            <Flex justify="center" align="center">
               {icon}
            </Flex>
         )}
      </Button>
   );
};

function DraftList({
   selectedDraft,
   onDraftSelect,
   onDraftSubmit,
   onDiscardDraft,
   submittingAllDrafts,
   handleSubmittingAllDrafts
}) {
   const isOnline = useOnlineStore((state) => state.isOnline);
   const [searchParams] = useSearchParams();
   const taskId = searchParams.get("taskId");
   const receiverId = searchParams.get("receiverId");

   // surveyId
   const { id } = useParams();
   const [submittingDraft, setSubmittingDraft] = useState({});
   const dataSource = useLiveQuery(() => db_drafts.where({ surveyId: id }).toArray(), [id], []);

   return (
      <Fragment>
         <List
            prefixCls="draft-list"
            rowKey="title"
            size="small"
            dataSource={[START_NEW_DRAFT, ...dataSource]}
            renderItem={({ draftId, draftTitle, draftDescription, timestamp }) => {
               const isStartNewOption = draftId === START_NEW_DRAFT.draftId;

               const isDraftSelected = selectedDraft === draftId;
               return (
                  <List.Item prefixCls="draft-list-item">
                     <Flex
                        className={`single-draft-card ${
                           isDraftSelected ? "draft-list-item-item_selected" : ""
                        }`}
                        justify="space-between"
                     >
                        <Flex className="single-draft-card--icon" justify="center" align="center">
                           <FileOutlined
                              style={{
                                 fontSize: "32px",
                                 color: "#5770CB"
                              }}
                           />
                        </Flex>
                        <Flex
                           className="single-draft-card--info-container"
                           justify="space-between"
                           style={{
                              width: "100%"
                           }}
                        >
                           <div className="single-draft-card--info">
                              <small
                                 style={{
                                    fontSize: "12px",
                                    fontWeight: "500",
                                    lineHeight: "14.4px",
                                    color: "#777777"
                                 }}
                              >
                                 {getDraftTimestamp(timestamp)}
                              </small>
                              <h6
                                 title={draftTitle}
                                 style={{
                                    fontFamily: "Poppins",
                                    fontSize: "16px",
                                    fontWeight: "500",
                                    color: "#222222"
                                 }}
                              >
                                 <Tooltip title={isStartNewOption ? "" : draftTitle}>
                                    {draftTitle}
                                 </Tooltip>
                              </h6>
                              <Flex align="top">
                                 <p>{draftDescription}</p>
                                 <span className="ml-2">
                                    <Popover
                                       placement="top"
                                       content={
                                          <p
                                             style={{
                                                maxWidth: "250px"
                                             }}
                                          >
                                             {draftDescription}
                                          </p>
                                       }
                                       title="Draft description"
                                       trigger="click"
                                    >
                                       <InfoCircleOutlined />
                                    </Popover>
                                 </span>
                              </Flex>
                           </div>
                           <Flex
                              className="single-draft-card--actions"
                              justify="space-between"
                              align="center"
                              gap={16}
                           >
                              {isStartNewOption ? (
                                 <ActionButton
                                    onClick={() => onDraftSelect(draftId)}
                                    icon={
                                       <Fragment>
                                          <span className="mr-2">Get started</span>
                                          <FormOutlined
                                             style={{
                                                fontSize: "20px",
                                                color: "#5770CB"
                                             }}
                                          />
                                       </Fragment>
                                    }
                                 />
                              ) : (
                                 <Fragment>
                                    <ActionButton
                                       disabled={isDraftSelected || submittingAllDrafts}
                                       onClick={() => onDraftSelect(draftId)}
                                       icon={
                                          <EditOutlined
                                             style={{
                                                fontSize: "20px",
                                                color: "#5770CB"
                                             }}
                                          />
                                       }
                                    />
                                    <Popconfirm
                                       title={
                                          <p style={{ maxWidth: "250px" }}>Delete {draftTitle}</p>
                                       }
                                       description="Are you sure to delete this draft?"
                                       onConfirm={() => onDiscardDraft(draftId)}
                                       okText="Yes"
                                       okButtonProps={{ danger: true }}
                                       cancelText="No"
                                    >
                                       <ActionButton
                                          icon={
                                             <DeleteOutlined
                                                style={{
                                                   fontSize: "20px",
                                                   color: "#5770CB"
                                                }}
                                             />
                                          }
                                       />
                                    </Popconfirm>

                                    <ActionButton
                                       loading={submittingDraft[draftId]}
                                       onClick={async () => {
                                          try {
                                             handleSubmittingAllDrafts();
                                             setSubmittingDraft({ [draftId]: true });
                                             await onDraftSubmit(draftId);
                                             handleSubmittingAllDrafts();
                                             setSubmittingDraft({ [draftId]: false });
                                          } catch (e) {
                                             setSubmittingDraft({ [draftId]: false });
                                             handleSubmittingAllDrafts();
                                          }
                                       }}
                                       icon={
                                          <SendOutlined
                                             style={{
                                                fontSize: "20px",
                                                color: "#5770CB",
                                                translate: "3px -2px"
                                             }}
                                             rotate={-45}
                                          />
                                       }
                                       hideSubmitDraftButton={!isOnline && (taskId || receiverId)}
                                       disabled={submittingAllDrafts}
                                    />
                                 </Fragment>
                              )}
                           </Flex>
                        </Flex>
                     </Flex>
                     {/* </Card> */}
                  </List.Item>
               );
            }}
         />
      </Fragment>
   );
}

export default DraftList;
