import React, { useState } from "react";
import { Button, Flex, InputNumber, message, Tooltip } from "antd";
import { AimOutlined, DeleteOutlined } from "@ant-design/icons";
import PreviewQuestionLabel from "../../../common/PreviewQuestionLabel/PreviewQuestionLabel";
import { useSurveyInputStore } from "../../../../surveyStore";
import useEvaluateConstraint from "../../question-preview/hooks/useEvaluateConstraint";
import PreviewQuestionConstraints from "../../../common/PreviewQuestionConstraints/PreviewQuestionConstraints";

import "./Geopoint.scss";

export const Geopoint = React.memo(
   ({ props, hasErrors, surveyId, userId, submissionId, label, hint }) => {
      const { id, questionType, isRequired, answers } = props;

      const [loadingLocation, setLoadingLocation] = useState(false);

      const inputState = useSurveyInputStore((state) => state.inputs?.[id]);
      const setInputs = useSurveyInputStore((state) => state.setInputs);

      const hasSubmissionId = Boolean(submissionId);

      const submissionAnswer = hasSubmissionId
         ? answers?.find((answer) =>
              props.repeatInstanceIndex
                 ? answer?.answerDetail.repeatGroup === props.repeatInstanceIndex
                 : answer
           )?.answerDetail?.answerGeoLocation
         : null;

      const value = hasSubmissionId ? submissionAnswer : inputState?.answerDetail?.coords ?? null;

      const passConstraint = useEvaluateConstraint(
         !hasSubmissionId ? props.constraint : null,
         value
      );

      const showErrors =
         hasErrors &&
         isRequired &&
         (!inputState ||
            inputState?.answerDetail?.coords === null ||
            !inputState?.answerDetail?.coords.latitude ||
            !inputState?.answerDetail?.coords.longitude ||
            !inputState?.answerDetail?.coords.accuracy);

      const onChange = (coords) => {
         if (submissionId) {
            return;
         }
         if (!coords) {
            setInputs({
               target: {
                  name: id,
                  value: ""
               }
            });
         } else {
            setInputs({
               target: {
                  name: id,
                  value: {
                     surveyId: surveyId,
                     userId: userId,
                     questionId: id,
                     questionType: questionType,
                     name: props.name,
                     skipLogicAnswer: coords,
                     answerDetail: {
                        coords
                     }
                  }
               }
            });
         }
      };

      const getLocation = () => {
         if (navigator.geolocation) {
            setLoadingLocation(true);
            navigator.geolocation.getCurrentPosition(
               (position) => {
                  const { latitude, longitude, accuracy, altitude } = position.coords;
                  setTimeout(() => {
                     onChange({ latitude, longitude, accuracy, altitude });
                     setLoadingLocation(false);
                  }, 500);
               },
               (error) => {
                  message.error(error.message ?? "Failed to get location. Please try again.");
                  console.error("Error getting location:", error);
                  setLoadingLocation(false);
               },
               {
                  enableHighAccuracy: true,
                  timeout: 10000
               }
            );
         } else {
            message.error("Geolocation is not supported by this browser.");
         }
      };

      const commonProps = {
         name: id,
         stringMode: true,
         size: "large",
         type: "number",
         readOnly: hasSubmissionId,
         status: showErrors || (passConstraint !== null && !passConstraint) ? "error" : "",
         prefixCls: "geopoint-submission-type-wrapper--input"
      };

      return (
         <div className="geopoint-submission-type-wrapper">
            <PreviewQuestionLabel label={label} isRequired={isRequired} hint={hint} />

            <div className="geopoint-submission-type-wrapper--gps">
               <Flex className="geopoint-submission-type-wrapper--gps_coords" gap={10}>
                  <div className="lat">
                     <label htmlFor="latitude">Latitude</label>
                     <InputNumber
                        {...commonProps}
                        id="latitude"
                        min="-90"
                        max="90"
                        value={value?.latitude}
                        onChange={(latitude) => onChange({ ...value, latitude })}
                     />
                  </div>
                  <div className="lng">
                     <label htmlFor="longitude">Longitude</label>
                     <InputNumber
                        {...commonProps}
                        id="longitude"
                        min="-180"
                        max="180"
                        value={value?.longitude}
                        onChange={(longitude) => onChange({ ...value, longitude })}
                     />
                  </div>
               </Flex>

               <Flex className="geopoint-submission-type-wrapper--gps_accuracy" gap={10}>
                  <div className="alt">
                     <label htmlFor="altitude">Altitude (m)</label>
                     <InputNumber
                        {...commonProps}
                        id="altitude"
                        value={value?.altitude}
                        onChange={(altitude) => onChange({ ...value, altitude })}
                     />
                  </div>
                  <div className="acc">
                     <label htmlFor="accuracy">Accuracy (m)</label>
                     <InputNumber
                        {...commonProps}
                        id="accuracy"
                        min="0"
                        value={value?.accuracy}
                        onChange={(accuracy) => onChange({ ...value, accuracy })}
                     />
                  </div>
                  {!hasSubmissionId && (
                     <div className="location">
                        <Tooltip title="Get current location">
                           <Button
                              loading={loadingLocation}
                              onClick={getLocation}
                              size="large"
                              icon={<AimOutlined />}
                           />
                        </Tooltip>
                        <Tooltip title="Clear all fields">
                           <Button
                              onClick={() => onChange(null)}
                              size="large"
                              icon={<DeleteOutlined />}
                              type="text"
                           />
                        </Tooltip>
                     </div>
                  )}
               </Flex>
            </div>

            <span className="geopoint-submission-type-wrapper--error">
               {showErrors ? "This field is required" : " "}
               {passConstraint !== null && !passConstraint ? (
                  <PreviewQuestionConstraints constraintMessages={props.constraintMessages} />
               ) : null}
            </span>
         </div>
      );
   }
);
