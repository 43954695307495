import React, { Fragment, useEffect, useMemo, useState } from "react";
import ItemSelector from "../question/question-preview/ItemSelector";
import ShowMoreLessButton from "../../../../../ui/Buttons/ShowMoreLess";
import { editDataStore, useSurveyInputStore, useTranslateStore } from "../../surveyStore";
import { Constants } from "../../../../../utils/constants";
import { InfoCircleOutlined } from "@ant-design/icons";
import { InputType } from "../../../../../utils/enums";
import useEvaluateConstraint from "../question/question-preview/hooks/useEvaluateConstraint";
import { LocalStorageService } from "../../../../../base/services/local-storage.service";
import { useParams } from "react-router-dom";

import "./Section.scss";

function Section({ surveyItem, hasErrors, submissionId, ...props }) {
   const [showRepeatedGroup, setShowRepeatedGroup] = useState(true);
   const setInputs = useSurveyInputStore((state) => state.setInputs);

   const isRepeat = !!surveyItem.repeat_count;
   const { toLanguage } = useTranslateStore();
   const surveyBaseLanguage = editDataStore((state) => state.surveyBaseLanguage);
   const { id: surveyId } = useParams();
   const userId = LocalStorageService?.getItem("user")?.id || "";

   const hasSkipLogic = surveyItem.surveyItem.relevant;
   const isQuestionSubmitted = useEvaluateConstraint(hasSkipLogic, true);

   const isArabicLanguage = !toLanguage
      ? surveyBaseLanguage === Constants.ARABIC_LANGUAGE_ID
      : toLanguage === Constants.ARABIC_LANGUAGE_ID;

   const repeatCount = useEvaluateConstraint(
      submissionId && !props.sectionInRepeat
         ? `${surveyItem.repeatGroup}`
         : surveyItem.repeat_count,
      true
   );

   const sortedSurveyItems = useMemo(() => {
      if ((isRepeat && !repeatCount && !submissionId) || !surveyItem?.innerItems) {
         return [];
      }

      if (isRepeat) {
         const repeatedItems = Array.from({ length: repeatCount }, () => [
            ...surveyItem.innerItems
         ]);

         repeatedItems.forEach((subArray) => {
            subArray.sort((a, b) => a.surveyItem.order - b.surveyItem.order);
         });

         return repeatedItems;
      }

      return [...surveyItem.innerItems]?.sort((a, b) => a.surveyItem.order - b.surveyItem.order);
   }, [isRepeat, repeatCount, submissionId, surveyItem]);

   const handleShowMoreButton = () => {
      setShowRepeatedGroup((prev) => !prev);
   };

   const shouldDisplayRepeatGroup =
      showRepeatedGroup &&
      isRepeat &&
      Array.isArray(sortedSurveyItems) &&
      Array.isArray(sortedSurveyItems[0]);

   useEffect(() => {
      if (!submissionId && surveyItem.repeat_count) {
         setInputs({
            target: {
               name: surveyItem.id,
               value: {
                  surveyId: surveyId,
                  userId: userId,
                  questionId: surveyItem.id,
                  questionType: InputType.SECTION,
                  name: surveyItem.surveyItem.name + "__counter",
                  skipLogicAnswer: repeatCount,
                  answerDetail: {
                     number: repeatCount
                  }
               }
            }
         });
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [repeatCount, surveyItem, submissionId, surveyId, userId]);

   return (
      <Fragment>
         {!hasSkipLogic || isQuestionSubmitted || submissionId ? (
            <div
               key={surveyItem.surveyItem?.name}
               className={`preview-survey-section${
                  isRepeat && !props.sectionInRepeat ? "--repeat" : ""
               } ${shouldDisplayRepeatGroup ? "bottomSpace" : ""}`}
               data-xpath={surveyItem.xpath}
            >
               {
                  <div
                     className={`preview-survey-section--header${isRepeat ? "--repeat" : ""} ${
                        shouldDisplayRepeatGroup ? "hasRepeatGroupShown" : ""
                     } `}
                  >
                     <h5
                        style={{
                           textAlign: isArabicLanguage ? "right" : "left"
                        }}
                     >
                        <span>
                           {!surveyItem.sectionLabel || !toLanguage
                              ? surveyItem.surveyItem?.description
                              : surveyItem.sectionLabel?.description}
                        </span>
                        {!isRepeat && (
                           <span style={{ marginLeft: "13px", fontSize: "16px" }}>
                              <InfoCircleOutlined />
                           </span>
                        )}
                     </h5>

                     <div
                        style={{
                           width: "150px"
                        }}
                     >
                        {isRepeat && (
                           <ShowMoreLessButton
                              onClick={handleShowMoreButton}
                              shouldShowMore={!showRepeatedGroup}
                           />
                        )}
                     </div>
                  </div>
               }
               <div
                  style={{
                     textAlign: isArabicLanguage ? "right" : "left"
                  }}
                  data-xpath={surveyItem.xpath}
               >
                  {isRepeat
                     ? sortedSurveyItems.map((repeatGroupQuestions, i) => {
                          const repeatInstanceIndex = props.repeatInstanceIndex
                             ? props.repeatInstanceIndex
                             : i + 1;

                          return (
                             <div
                                key={i}
                                className={`preview-survey-section--questions_wrapper${
                                   isRepeat ? "--repeat" : ""
                                } ${isRepeat && !showRepeatedGroup ? "hideRepeatedGroup" : ""}`}
                             >
                                {!props.sectionInRepeat && (
                                   <div className="iteration-indicator">{repeatInstanceIndex}.</div>
                                )}
                                {repeatGroupQuestions.map((question, j) => {
                                   const repeatQuestionId = `${question.xpath.replace(
                                      `/${question.surveyItem.name}`,
                                      ""
                                   )}/${question.id}/${repeatInstanceIndex}`;
                                   const repeatQuestionName = `${question.surveyItem.name}/${repeatInstanceIndex}`;

                                   if (question.itemLabel === "Section") {
                                      return (
                                         <Section
                                            key={j}
                                            hasErrors={hasErrors}
                                            sectionInRepeat={true}
                                            surveyItem={{
                                               ...question,
                                               repeat_count: "1", // to display only one instance of the repeated group because it's actually a section
                                               id: repeatQuestionId,
                                               surveyItem: {
                                                  ...question.surveyItem,
                                                  name: repeatQuestionName
                                               }
                                            }}
                                            submissionId={submissionId}
                                            repeatInstanceIndex={repeatInstanceIndex}
                                         />
                                      );
                                   } else {
                                      return (
                                         <ItemSelector
                                            key={j}
                                            props={{
                                               ...question.surveyItem,
                                               id: repeatQuestionId,
                                               name: repeatQuestionName,
                                               repeatInstanceIndex
                                            }}
                                            hasErrors={hasErrors}
                                            submissionId={submissionId}
                                            isRepeat={isRepeat}
                                         />
                                      );
                                   }
                                })}
                             </div>
                          );
                       })
                     : sortedSurveyItems.map((item, i) => {
                          if (item.itemLabel === "Section") {
                             return (
                                <Section
                                   key={i}
                                   hasErrors={hasErrors}
                                   surveyItem={item}
                                   submissionId={submissionId}
                                />
                             );
                          } else {
                             const previousItem = sortedSurveyItems[i - 1];
                             const isPreviousItemSection = previousItem?.itemLabel === "Section";
                             const invisibleQuestions = [
                                InputType.CALCULATE_QUESTION,
                                InputType.HIDDEN
                             ].includes(item?.surveyItem?.questionType);

                             return (
                                <div
                                   className={`preview-survey-section--questions_wrapper ${
                                      isPreviousItemSection && !invisibleQuestions
                                         ? "extra-padding"
                                         : ""
                                   } ${invisibleQuestions ? "p-0" : ""}`}
                                   key={i}
                                >
                                   <ItemSelector
                                      props={item.surveyItem}
                                      hasErrors={hasErrors}
                                      submissionId={submissionId}
                                      xpath={item.xpath}
                                   />
                                </div>
                             );
                          }
                       })}
               </div>
            </div>
         ) : null}
      </Fragment>
   );
}

export default Section;
