import React, { Fragment, useEffect, useMemo } from "react";
import { Checkbox, Select } from "antd";
import PreviewQuestionLabel from "../../../common/PreviewQuestionLabel/PreviewQuestionLabel";
import { useSurveyInputStore } from "../../../../surveyStore";
import useEvaluateConstraint from "../../question-preview/hooks/useEvaluateConstraint";
import PreviewQuestionConstraints from "../../../common/PreviewQuestionConstraints/PreviewQuestionConstraints";
import useEvaluateChoiceFilter from "../../question-preview/hooks/useEvaluateChoiceFilter";
import useEvaluateAppearance from "../../question-preview/hooks/useEvaluateAppearance";
import { getForeignInputNames } from "../../question-preview/helpers/getForeignInputNames";
import useInputValuesByName from "../../question-preview/hooks/useInputValueByName";
import { REGEX } from "../../../../../../../helpers/constraintParsers";

import "./SelectMultiple.scss";

const CheckboxGroup = Checkbox.Group;

export const SelectMultiple = React.memo(
   ({ props, hasErrors, surveyId, userId, language, submissionId, label, hint }) => {
      const { id, selectQuestionChoices, questionType, isRequired, answers } = props;
      let maxChoices = props.maxChoices;

      const inputState = useSurveyInputStore((state) => state.inputs?.[id]);
      const setInputs = useSurveyInputStore((state) => state.setInputs);

      const hasSubmissionId = Boolean(submissionId);

      const passConstraint = useEvaluateConstraint(
         !hasSubmissionId ? props.constraint : null,
         inputState?.skipLogicAnswer
      );

      const answer = answers?.find((answer) =>
         props.repeatInstanceIndex
            ? answer?.answerDetail.repeatGroup === props.repeatInstanceIndex
            : answer
      );

      const submissionAnswer = hasSubmissionId
         ? answer?.answerDetail?.choices.map((choice) => choice.choiceId)
         : inputState?.answerDetail?.choices;

      const showErrors =
         hasErrors &&
         isRequired &&
         (!inputState || inputState?.answerDetail?.choices?.length === 0);

      const derivedOptions = useEvaluateChoiceFilter({
         choiceFilter: hasSubmissionId ? null : props.choiceFilter,
         name: props.name,
         selectQuestionChoices,
         repeatInstanceIndex: props.repeatInstanceIndex,
         isRepeat: !!props.repeatInstanceIndex
      });

      const appearance = useEvaluateAppearance({
         appearance: props.appearance
      });

      const inputNames = getForeignInputNames(
         derivedOptions.map((option) => option.choiceLabels[0]?.text).join(" ")
      );
      const hasInputs = inputNames.length > 0;
      const inputValuesObject = useInputValuesByName(inputNames);

      const selectOptions = useMemo(() => {
         return derivedOptions.map((option) => {
            const translatedLabel =
               option.choiceLabels.find((choice) => choice.languageId === language)?.text ||
               option.choiceLabels[0]?.text;

            const label = hasInputs
               ? translatedLabel.replace(
                    REGEX.foreignInputValue,
                    (_, inputName) => inputValuesObject[inputName] ?? "[No value]"
                 )
               : translatedLabel;

            return {
               value: option.id,
               name: option.name,
               label: label,
               disabled:
                  maxChoices &&
                  inputState?.answerDetail?.choices?.length >= maxChoices &&
                  !inputState?.answerDetail?.choices?.includes(option.id)
            };
         });
      }, [
         derivedOptions,
         hasInputs,
         inputState?.answerDetail?.choices,
         inputValuesObject,
         language,
         maxChoices
      ]);

      const handleCheckBoxGroupChange = (value) => {
         if (hasSubmissionId) {
            return;
         }

         if (value.length === 0) {
            setInputs({
               target: {
                  name: id,
                  value: ""
               }
            });
         } else {
            const selectedChoices = selectOptions.filter((choice) =>
               value.some((i) => [choice.value, choice.name].includes(i))
            );

            const selectedNames = selectedChoices.map((choice) => choice.name);
            const selectedValues = selectedChoices.map((choice) => choice.value);

            setInputs({
               target: {
                  name: id,
                  value: {
                     surveyId: surveyId,
                     userId: userId,
                     questionId: id,
                     name: props.name,
                     skipLogicAnswer: selectedNames,
                     questionType: questionType,
                     answerDetail: {
                        choices: selectedValues
                     }
                  }
               }
            });
         }
      };

      useEffect(() => {
         if (props.default) {
            //#TODO check null value error on split
            const defaultOptions = props.default.split("and").map((s) => s.trim());
            handleCheckBoxGroupChange(defaultOptions);
         }
         // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [props.default]);

      useEffect(() => {
         if (
            props.choiceFilter &&
            !derivedOptions.find((opt) => submissionAnswer?.includes(opt.id))
         ) {
            setInputs({ target: { name: id, value: "" } });
         }
         // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [derivedOptions, id, props.choiceFilter, submissionAnswer]);

      return (
         <div className="select_multiple-submission-type">
            <PreviewQuestionLabel label={label} isRequired={isRequired} hint={hint} />

            <div
               className={`select_multiple-submission-type--options${
                  !appearance.includes("minimal") &&
                  (showErrors || (passConstraint !== null && !passConstraint))
                     ? "_error"
                     : ""
               }`}
            >
               {appearance.includes("minimal") ? (
                  <Select
                     allowClear
                     showSearch={false}
                     mode="multiple"
                     style={{ width: "100%" }}
                     size="large"
                     status={
                        showErrors || (passConstraint !== null && !passConstraint) ? "error" : ""
                     }
                     placeholder="none selected"
                     value={submissionAnswer}
                     options={selectOptions}
                     onChange={handleCheckBoxGroupChange}
                     maxTagCount={0}
                     maxTagPlaceholder={(values) =>
                        values.length > 1
                           ? `${values.length} selected`
                           : values.map(({ label }) => label).join(", ")
                     }
                  />
               ) : (
                  <Fragment>
                     <CheckboxGroup
                        value={submissionAnswer}
                        options={selectOptions}
                        onChange={handleCheckBoxGroupChange}
                        prefixCls="select_multiple-submission-type--options---checkbox"
                     />
                     {typeof maxChoices !== "undefined" && maxChoices > 0 && (
                        <div className="select_multiple-submission-type--options---max_choice">
                           {`You can select up to ${maxChoices} choices.`}
                        </div>
                     )}
                  </Fragment>
               )}
            </div>

            <span className="select_multiple-submission-type--error">
               {showErrors ? "You must select at least one" : " "}
               {passConstraint !== null && !passConstraint ? (
                  <PreviewQuestionConstraints constraintMessages={props.constraintMessages} />
               ) : null}
            </span>
         </div>
      );
   }
);
