import React from "react";
import Marked from "../../../../../../shared/Marked";

import "./PreviewQuestionLabel.scss";

function PreviewQuestionLabel({ label, isRequired, hint }) {
   return (
      <div className="preview-question">
         <h5 className={`preview-question-label ${isRequired ? "required" : ""}`}>
            <Marked className="marked">{label}</Marked>
         </h5>
         {hint && (
            <span className="preview-question-hint">
               <Marked className="marked">{hint}</Marked>
            </span>
         )}
      </div>
   );
}

// export default PreviewQuestionLabel;
export default React.memo(PreviewQuestionLabel);
