import React, { useRef, useState } from "react";
import { Button, Col, Row, Upload, message, notification } from "antd";
import Icon, { UploadOutlined } from "@ant-design/icons";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import BasicModal from "../BasicModal/BasicModal";
import { usePersonalDataStore, useProfileStore } from "../../pages/auth/authStore";
import AuthService from "../../base/services/authentication.service";
import { httpService } from "../../base/services/httpService.service";
import { StatusCodesEnum } from "../../utils/enums";
import { scaleImage } from "../../utils/scaleImage";

import "./FileUpload.scss";

const FileUpload = React.memo(({ handleFileUrl, handleCancel }) => {
   const userId = AuthService.getUser()?.id || "";
   const [fileList, setFileList] = useState([]);
   const [previewVisible, setPreviewVisible] = useState(false);
   const [croppedImage, setCroppedImage] = useState(null);
   const [croppingImage, setCroppingImage] = useState(false);
   const [removerProfilePicture, setRemoverProfilePicture] = useState(false);
   const personalData = usePersonalDataStore((state) => state.personalData);
   const setPersonalData = usePersonalDataStore((state) => state.setPersonalData); // Get action from store
   const { userLetter } = useProfileStore();
   const cropperRef = useRef(null);

   const handleFileChange = ({ fileList }) => {
      const latestFile = fileList.length > 0 ? [fileList[fileList.length - 1]] : [];
      setFileList(latestFile);

      if (fileList.length > 0) {
         setPreviewVisible(true);
      }
   };

   const handleCrop = () => {
      const cropper = cropperRef.current.cropper;
      const croppedCanvas = cropper.getCroppedCanvas();
      croppedCanvas.toBlob(async (blob) => {
         const croppedFile = new File([blob], fileList[0].name, {
            type: "image/jpeg",
            lastModified: Date.now()
         });

         try {
            const scaledFile = await scaleImage(croppedFile, 150, 0.75);
            setCroppedImage(scaledFile);
         } catch (error) {
            message.error("Failed to scale image.");
            setCroppedImage(croppedFile);
         }

         setPreviewVisible(false);
         setRemoverProfilePicture(false);
      });
   };

   const handleUpload = async () => {
      if (!croppedImage && !removerProfilePicture) {
         console.error("Please crop the image before uploading.");
         return;
      }

      const formData = new FormData();
      formData.append("UserId", userId);
      formData.append("ProfilePicture", removerProfilePicture ? "" : croppedImage);

      try {
         setCroppingImage(true);
         const response = await httpService.post("/Account/addOrUpdateProfilePicture", formData);
         if (!response) return;

         const isSuccess = response.statusCode === StatusCodesEnum.OK;
         if (isSuccess) {
            handleFileUrl();

            if (removerProfilePicture) {
               clearProfilePicture();
            } else {
               updateUserProfilePicture(croppedImage);
            }
         } else {
            notification.warning({
               type: "warning",
               message: response.data.message || "Something went wrong! Please try again later."
            });
         }
      } catch (error) {
         notification.error({
            message:
               error?.response?.data?.message ||
               `Unable to ${removerProfilePicture ? "delete" : "upload"} profile picture`
         });
      } finally {
         setCroppingImage(false);
      }
   };

   const updateUserProfilePicture = (imageData) => {
      const reader = new FileReader();
      reader.onloadend = () => {
         const base64data = reader.result;

         setPersonalData({ ...personalData, profilePictureUrl: base64data });
      };
      reader.readAsDataURL(imageData);
   };

   const clearProfilePicture = () => {
      setPersonalData({ ...personalData, profilePictureUrl: "" });
      setCroppedImage("");
      setRemoverProfilePicture(false);
   };

   const handleRemoveProfilePicture = () => {
      setRemoverProfilePicture(true);
   };

   return (
      <div className="file-upload-page">
         <h3 className="file-upload--title">Edit profile picture</h3>
         <div className="user-profile-logo">
            {(personalData.profilePictureUrl || croppedImage) && !removerProfilePicture ? (
               <>
                  <img
                     width={120}
                     height={120}
                     src={
                        croppedImage
                           ? URL.createObjectURL(croppedImage)
                           : personalData.profilePictureUrl
                     }
                     alt=""
                     style={{
                        borderRadius: "100px"
                     }}
                  />
                  <div className="delete-icon" onClick={handleRemoveProfilePicture}>
                     <Icon
                        style={{ fontSize: 20, color: "#fff" }}
                        component={() => (
                           <svg
                              width="20"
                              height="20"
                              viewBox="0 0 20 20"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                           >
                              <path
                                 d="M1.42859 5.00153H18.5714M6.42859 5.00153V4.28725C6.42859 3.34005 6.80486 2.43163 7.47463 1.76186C8.1444 1.09209 9.05282 0.71582 10 0.71582C10.9472 0.71582 11.8556 1.09209 12.5254 1.76186C13.1952 2.43163 13.5714 3.34005 13.5714 4.28725V5.00153M7.85716 9.28934V15.0058M12.1429 9.28934V15.0058M3.57145 5.00153H16.4286V17.8587C16.4286 18.2375 16.278 18.601 16.0102 18.8688C15.7423 19.1367 15.3789 19.2872 15 19.2872H5.00002C4.62113 19.2872 4.25777 19.1367 3.98986 18.8688C3.72196 18.601 3.57145 18.2375 3.57145 17.8587V5.00153Z"
                                 stroke="#fff"
                                 strokeWidth="1.25"
                                 strokeLinecap="round"
                                 strokeLinejoin="round"
                              />
                           </svg>
                        )}
                     />
                     <p>Delete profile picture</p>
                  </div>
               </>
            ) : (
               <span>{userLetter}</span>
            )}
         </div>
         <p className="file-upload-page--paragraph">Upload a file to change your profile picture</p>
         <Upload.Dragger
            name="file"
            beforeUpload={() => false} // Prevent automatic upload
            onChange={handleFileChange}
            className="upload-file"
            accept=".png, .jpg, .jpeg"
            showUploadList={false}
            progress="trailColor"
            prefixCls="upload-file"
         >
            <div className="centered-container">
               <div className="icon-box">
                  <UploadOutlined style={{ color: "#4158CF", fontSize: "20px" }} />
               </div>
               <p className="upload-file--text">
                  Drag and drop files here, or click to select files
               </p>
               <span className="upload-file-span">JPG or PNG</span>
               <br />
               <span>Max file size: 20MB</span>
            </div>
         </Upload.Dragger>
         <BasicModal
            isModalOpen={previewVisible}
            handleCancel={() => {
               setPreviewVisible(false);
            }}
            footerButtonCustomStyle
         >
            <Cropper
               ref={cropperRef}
               src={fileList.length > 0 ? URL.createObjectURL(fileList[0].originFileObj) : null}
               style={{ height: 400, width: "100%" }}
               aspectRatio={1}
               viewMode={1}
               minCropBoxHeight={10}
               minCropBoxWidth={10}
               background={false}
               responsive={true}
               autoCropArea={1}
               checkOrientation={false}
               guides={true}
            />
            <br />
            <Button className="primaryButton" onClick={handleCrop}>
               Crop
            </Button>
         </BasicModal>
         <Row gutter={16} className="mt-8" justify="center" align="middle">
            <Col>
               <Button className="cancel" disabled={croppingImage} onClick={handleCancel}>
                  Cancel
               </Button>
            </Col>
            <Col>
               <Button className="primaryButton" onClick={handleUpload} loading={croppingImage}>
                  Change profile picture
               </Button>
            </Col>
         </Row>
      </div>
   );
});

export default FileUpload;
