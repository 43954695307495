import objectPath from "object-path";
import React, { useEffect, useLayoutEffect, useMemo } from "react";
import { useLocation, Link, useNavigate } from "react-router-dom";
import { Button, Dropdown } from "antd";
import { useHtmlClassService } from "../../_core/MetronicLayout";
import { getBreadcrumbsAndTitle, useSubheader } from "../../_core/MetronicSubheader";
import { UserRolesEnum } from "../../../../app/pages/auth/userRoles.enum";
import { usePersonalDataStore } from "../../../../app/pages/auth/authStore";
import { LocalStorageService } from "../../../../app/base/services/local-storage.service";
import AuthService from "../../../../app/base/services/authentication.service";
import { ReactComponent as BackArrowIcon } from "./backArrowIcon.svg";
import { returnPreviousPage } from "./helper";
import { toAbsoluteUrl } from "../../../_helpers";

import "../../custom.css";

export function SubHeader() {
   const user = AuthService.getUser();
   const userDetails = JSON.parse(localStorage.getItem("roles"));
   const { personalData } = usePersonalDataStore();

   const dropdownItems = [
      ...(!AuthService.getOrganizationId() ||
      !userDetails ||
      AuthService.isAuthorized([UserRolesEnum.DataCollector, UserRolesEnum.Guest])
         ? []
         : [
              ...(AuthService.isAuthorized([UserRolesEnum.Organization])
                 ? [
                      {
                         key: "4",
                         label: <Link to="/account-summary">Account summary</Link>
                      }
                   ]
                 : []),
              {
                 key: "1",
                 label: <Link to="/profile-information">Profile information</Link>
              },
              {
                 key: "2",
                 label: <Link to="/login-information">Login information</Link>
              },
              ...(AuthService.isAuthorized([UserRolesEnum.Organization])
                 ? [
                      {
                         key: "3",
                         label: <Link to="/subscription-plans">Payments</Link>
                      }
                   ]
                 : []),
              {
                 key: "5",
                 type: "divider"
              }
           ]),
      {
         key: "6",
         style: { padding: 0 },
         label: (
            <Button
               type="link"
               className="w-full"
               style={{ color: "red" }}
               onClick={AuthService.logout}
            >
               Logout
            </Button>
         )
      }
   ];

   const roles = userDetails?.find((x) =>
      [
         UserRolesEnum.JuniorProjectManager,
         UserRolesEnum.ProjectManager,
         UserRolesEnum.Guest
      ].includes(x.id)
   );

   const isSingleOrganization = user?.organizations?.length === 1;
   const uiService = useHtmlClassService();
   const location = useLocation();
   const navigate = useNavigate();
   const { text, path } = returnPreviousPage(location.pathname, roles, isSingleOrganization);
   const subheader = useSubheader();
   const organizationName = LocalStorageService.getItem("organizationName");

   const layoutProps = useMemo(() => {
      return {
         config: uiService.config,
         subheaderFixed: objectPath.get(uiService.config, "subheader.fixed"),
         subheaderMobileToggle: objectPath.get(uiService.config, "subheader.mobile-toggle"),
         subheaderCssClasses: uiService.getClasses("subheader", true),
         subheaderContainerCssClasses: uiService.getClasses("subheader_container", true)
      };
   }, [uiService]);

   useLayoutEffect(() => {
      const aside = getBreadcrumbsAndTitle("kt_aside_menu", location.pathname);
      const header = getBreadcrumbsAndTitle("kt_header_menu", location.pathname);
      const breadcrumbs =
         aside && aside.breadcrumbs.length > 0 ? aside.breadcrumbs : header.breadcrumbs;
      subheader.setBreadcrumbs(breadcrumbs);
      subheader.setTitle(
         aside && aside.title && aside.title.length > 0 ? aside.title : header.title
      );

      // eslint-disable-next-line
   }, [location.pathname]);

   // Do not remove this useEffect, need from update title/breadcrumbs outside (from the page)
   useEffect(() => {}, [subheader]);

   return (
      <div
         id="kt_subheader"
         className={`subheader py-2 py-lg-4 mx-3 ${layoutProps.subheaderCssClasses}`}
      >
         <div
            className={
               location.pathname.includes("mytasks") || location.pathname.includes("organizations")
                  ? "myTasksHeader"
                  : `${layoutProps.subheaderContainerCssClasses} d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap`
            }
            style={
               location.pathname.includes("mytasks") || location.pathname.includes("organizations")
                  ? {
                       display: "flex",
                       justifyContent: "space-between",
                       width: "100%",
                       margin: "0 20px"
                    }
                  : undefined
            }
         >
            {!location.pathname.includes("/organizations") ? (
               (!location.pathname.includes("admin") ||
                  !location.pathname.includes("admin-organization-management")) && (
                  <button className="d-flex backIcon" type="button" onClick={() => navigate(path)}>
                     {!(
                        location.pathname.includes("admin-user-management") ||
                        location.pathname.includes("admin-organization-management") ||
                        location.pathname.includes("organizations") ||
                        (isSingleOrganization &&
                           (location.pathname.includes("admin-workspaces") ||
                              location.pathname.includes("workspace") ||
                              location.pathname.includes("mytasks")))
                     ) && (
                        <>
                           <BackArrowIcon />
                           <h1>{text}</h1>
                        </>
                     )}
                  </button>
               )
            ) : (
               <div
                  style={{
                     display: "flex",
                     alignItems: "center"
                  }}
               >
                  <img
                     alt="logo"
                     src={toAbsoluteUrl("/media/logos/kapSurvey-logo.png")}
                     className="max-h-20px"
                  />
               </div>
            )}

            <div className="d-flex align-items-center flex-wrap mr-1">
               {/* begin::Mobile Toggle */}
               {layoutProps.subheaderMobileToggle && (
                  <button
                     className="burger-icon burger-icon-left mr-4 d-inline-block d-lg-none"
                     id="kt_subheader_mobile_toggle"
                  >
                     <span />
                  </button>
               )}
               {/* end::Mobile Toggle */}
            </div>
            <div className="subheader-rigth d-flex align-items-center">
               {/* LOGO BURADA OLACAK */}
               <div className="user-info mr-2">
                  <span>{user?.fullName}</span>
                  <br />
                  {organizationName}
               </div>
               <div>
                  <Dropdown
                     prefixCls="profile-dropdown"
                     menu={{
                        mode: "vertical",
                        items: dropdownItems
                     }}
                     placement="bottomRight"
                     arrow
                     trigger="click"
                  >
                     <button
                        aria-controls="simple-menu"
                        aria-haspopup="true"
                        className="btn btn-icon font-weight-bolds top-profile-btn"
                        data-toggle="modal"
                        data-target="#kt_chat_modal"
                     >
                        {personalData.profilePictureUrl ? (
                           <img
                              alt=""
                              src={personalData.profilePictureUrl}
                              style={{
                                 height: "40px",
                                 borderRadius: "50%"
                              }}
                           ></img>
                        ) : (
                           <span className="user-letter" style={{ fontSize: "26px" }}>
                              {user?.fullName.charAt(0)}
                           </span>
                        )}
                     </button>
                  </Dropdown>
               </div>
            </div>
         </div>
      </div>
   );
}
